import axios from "axios";

import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { isArray, isEmpty } from "lodash";

import ResponseItem from "components/ResponseItem";
import ErrorLoading from "components/ErrorLoading";
import LoadingSkeleton from "components/LoadingSkeleton";

import { Survey } from "types/models";
import { ReactComponent as BGIcons } from "assets/BGIcons.svg";

import "./styles.sass";

const Responses = () => {
  const navigation = useNavigate();
  const location = useLocation();

  const { isLoading, error, data } = useQuery<Survey[]>(
    "surveysAll",
    async () => {
      const response = await axios.get<Survey[]>("/surveys");
      return response.data;
    }
  );

  const filteredByPublic = data
    ?.filter((survey) => survey.isPublic)
    .filter((survey) => !survey.archived);

  useEffect(() => {
    if (!(location.state as { from: string })?.from) {
      if (!isLoading && filteredByPublic?.length === 1) {
        const surveyId = filteredByPublic[0]?._id;
        navigation("/response/" + surveyId, { replace: true });
      }
    }
  }, [filteredByPublic, navigation, isLoading, location]);

  const content = useMemo(() => {
    if (isLoading) return <LoadingSkeleton />;

    if (error) return <ErrorLoading title="Can't load responses" />;

    const mappedData = filteredByPublic?.map((el) => ({
      survey: el,
    }));

    return (
      isArray(mappedData) &&
      (isEmpty(mappedData) ? (
        <h3 className="responses__empty">No available surveys yet</h3>
      ) : (
        <ul className="responses__surveys">
          {mappedData?.map((item) => (
            <ResponseItem
              key={item.survey._id}
              item={item}
              navigateUrl="/response/"
            />
          ))}
        </ul>
      ))
    );
  }, [error, isLoading, filteredByPublic]);

  // TODO map responses together with surveys

  return (
    <div className="responses">
      <h1 className="responses__headline">Your surveys</h1>
      {content}
      <div className="svg-background">
        <BGIcons />
      </div>
    </div>
  );
};

export default Responses;
