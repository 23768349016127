import axios, { AxiosError } from "axios";
import { values } from "lodash";
import { FC, useEffect } from "react";
import { useQuery } from "react-query";
import SingleTeamResult from "../SingleTeamResult";
import { SimpleTeam, Company, CategoryResults } from "types/models";

interface IProps {
  companyId: string;
  surveyId: string;
  setDataForCompany: (
    companyId: string,
    data: CategoryResults[],
    hidden: boolean
  ) => void;
  setDataForTeam: (
    companyId: string,
    teamInfo: SimpleTeam,
    data: CategoryResults[],
    hidden: boolean
  ) => void;
  setIsDataSet: (isDataSet: boolean) => void;
  teamId: string;
  hidden?: boolean;
}

const SingleCompanyResult: FC<IProps> = ({
  companyId,
  surveyId,
  setDataForCompany,
  setDataForTeam,
  hidden = false,
  setIsDataSet,
}) => {
  const { data } = useQuery<{ [key: string]: CategoryResults }, AxiosError>(
    `surveyResultsAll-${surveyId}-${companyId}`,
    async () => {
      const { data: categoryResultData } = await axios.get<{
        [key: string]: CategoryResults;
      }>(`/survey-results/${surveyId}/companies/${companyId}`);
      return categoryResultData;
    }
  );

  const { data: companyData } = useQuery<{ company: Company }, AxiosError>(
    `companies${companyId}`,
    async () => {
      const { data: companyDataResult } = await axios.get<{ company: Company }>(
        `/companies/${companyId}`
      );
      return companyDataResult;
    },
    { enabled: !!companyId }
  );

  useEffect(() => {
    if (data) {
      setDataForCompany(companyId, values(data), hidden);
      setIsDataSet(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {companyData?.company?.teams?.map?.((team) => (
        <SingleTeamResult
          key={team._id}
          surveyId={surveyId}
          companyId={companyId}
          teamInfo={{
            teamId: team._id,
            teamName: team.name,
            pointColor: team.pointColor,
            pointShape: team.pointShape,
          }}
          setDataForTeam={setDataForTeam}
          setIsDataSet={setIsDataSet}
        />
      ))}
    </>
  );
};

export default SingleCompanyResult;
