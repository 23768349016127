import { useMemo } from "react";
import { ReactComponent as RightArrowIcon } from "assets/RightArrow.svg";
import { ReactComponent as ChartIcon } from "assets/Chart.svg";

const StatusInfo = ({ status }: { status: string }) => {
  const content = useMemo(() => {
    switch (status) {
      case "finished":
        return (
          <>
            <ChartIcon />
            <span className="response-item__status-text">Check results</span>
          </>
        );
      case "pending":
        return (
          <>
            <span className="response-item__status-text">
              Return to filling
            </span>
            <RightArrowIcon />
          </>
        );
      case "new":
        return (
          <>
            <span className="response-item__status-text">Start filling</span>
            <RightArrowIcon />
          </>
        );
      default:
        return null;
    }
  }, [status]);

  return (
    <div className="response-item__status-wrapper">
      <span className="response-item__status">{content}</span>
    </div>
  );
};

export default StatusInfo;
