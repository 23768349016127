import axios, { AxiosError } from "axios";
import { isEmpty } from "lodash";
import React, { FC, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { CategoryResults, CompanyRole, FilterSets, Team } from "types/models";
import { filterSetHasResults, getColorByEmail } from "utils/utils";
import ColoredShape from "components/ColoredShape";
import { ActionIcon, Tooltip } from "@mantine/core";
import { ExclamationTriangleIcon } from "@modulz/radix-icons";
import { Switch } from "components/Switch";

interface IProps {
  role: CompanyRole;
  team: Team;
  setFilterSets: React.Dispatch<React.SetStateAction<FilterSets>>;
  companyId: string;
  surveyId: string;
  filterSets: FilterSets;
}

const SingleTeamMemberResults: FC<IProps> = ({
  role,
  team,
  surveyId,
  companyId,
  setFilterSets,
  filterSets,
}) => {
  const { data: resultPerUser } = useQuery<CategoryResults[], AxiosError>(
    ["surveyResultsAll", surveyId, companyId, team._id, role._id],
    async () => {
      const { data } = await axios.get<CategoryResults[]>(
        `/survey-results/${surveyId}/companies/${companyId}/teams/${team._id}/users/${role.userId}`
      );
      return data;
    }
  );

  const currentUserFilterSet = useMemo(() => {
    return filterSets[role.userId];
  }, [filterSets, role.userId]);

  useEffect(() => {
    if (!isEmpty(resultPerUser)) {
      setFilterSets((prevFilterSets) => ({
        ...prevFilterSets,
        [role.userId]: {
          _id: role.userId,
          name: `${team.name} ${role.email}`,
          categories: resultPerUser || [],
          filters: {},
          pointColor: getColorByEmail(role.email),
          pointShape: team.pointShape,
          visible: false,
          user: {
            _id: role.userId,
            email: role.email,
          },
          team: {
            _id: role.teamId,
            name: team._id,
          },
        },
      }));
    }
  }, [
    resultPerUser,
    role.email,
    role.teamId,
    role.userId,
    setFilterSets,
    team._id,
    team.name,
    team.pointShape,
  ]);

  if (!currentUserFilterSet) {
    return null;
  }

  const handleVisible = () => {
    setFilterSets((prevFilterSets) => ({
      ...prevFilterSets,
      [role.userId]: {
        ...prevFilterSets[role.userId],
        visible: !currentUserFilterSet.visible,
      },
    }));
  };

  return (
    <div className="filters__item">
      <div className="filters__icon">
        <ColoredShape
          shape={currentUserFilterSet?.pointShape}
          color={currentUserFilterSet?.pointColor}
        />
      </div>
      <span onClick={handleVisible} className="filters__name">
        {currentUserFilterSet?.user?.email
          ? currentUserFilterSet?.user?.email.split("@")[0]
          : "unknown"}
      </span>
      {!filterSetHasResults(currentUserFilterSet) && (
        <Tooltip
          color="orange"
          className="filters__warning"
          label="Can't find any results for filter set."
        >
          <ActionIcon color="orange" variant="filled">
            <ExclamationTriangleIcon width={20} height={20} />
          </ActionIcon>
        </Tooltip>
      )}
      <Switch value={currentUserFilterSet.visible} setValue={handleVisible} />
    </div>
  );
};

export default SingleTeamMemberResults;
