import { useContext, useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";
import { some } from "lodash";

import { ProfileContext } from "routes";
import ErrorLoading from "components/ErrorLoading";

const ProtectedRoute = () => {
  const { companyId, teamId } = useParams();
  const { profile } = useContext(ProfileContext);

  const isUserAllowed = useMemo(
    () =>
      (teamId &&
        some(profile?.privileges, {
          role: "TEAM_LEADER",
          team: { _id: teamId },
        })) ||
      (companyId &&
        some(profile?.privileges, {
          role: "COMPANY_ADMIN",
          company: { _id: companyId },
        })) ||
      (!companyId &&
        !teamId &&
        some(profile?.privileges, { role: "SUPER_ADMIN" })),
    [profile, companyId, teamId]
  );

  return isUserAllowed ? (
    <Outlet />
  ) : (
    <ErrorLoading title="Unauthorized access" />
  );
};

export default ProtectedRoute;
