import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as LeftArrowIcon } from "assets/LeftArrow.svg";

import "./styles.sass";

interface IProps {
  path?: string;
  name?: string;
}

const BackToScreen: FC<IProps> = ({ path, name = "Dashboard" }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      onClick={() =>
        path
          ? navigate(path, { state: { from: location.pathname } })
          : navigate(-1)
      }
      className="back__wrapper"
      data-testid="back-to-button"
    >
      <LeftArrowIcon className="left-arrow" />
      <span data-testid="back-to-label">Back to {name}</span>
    </div>
  );
};

export default BackToScreen;
