import { SegmentedControl } from "@mantine/core";
import { FC } from "react";

import "./styles.sass";

interface IProps {
  availableCategories: { value: string; label: string }[];
  categoriesToFilter: string[];
  setCategoriesToFilter: React.Dispatch<React.SetStateAction<string[]>>;
}

const CategoryFilters: FC<IProps> = ({
  setCategoriesToFilter,
  categoriesToFilter,
  availableCategories,
}) => {
  return (
    <div className="category-filter">
      <SegmentedControl
        color="teal"
        size="sm"
        data={[
          ...availableCategories,
          { label: "All categories", value: "all" },
        ]}
        value={categoriesToFilter[0]}
        onChange={(select) => setCategoriesToFilter([select])}
      />
    </div>
  );
};

export default CategoryFilters;
