import { Button } from "@mantine/core";
import { useLottie } from "lottie-react";
import { useNavigate } from "react-router-dom";
import leaderJson from "./leader.json";
import memberJson from "./member.json";

import "./styles.sass";

interface AnimationProps {
  animationType: "member" | "leader";
}

const Animation = ({ animationType }: AnimationProps) => {
  const navigate = useNavigate();

  const skip = () => navigate("/landing");

  const { View } = useLottie({
    animationData: animationType === "member" ? memberJson : leaderJson,
    autoPlay: true,
    loop: false,
    onComplete: () => {
      skip();
    },
  });

  return (
    <div className="animation">
      {View}
      <Button
        variant="light"
        className="animation__floating-button"
        onClick={skip}
        size="xl"
      >
        Skip
      </Button>
    </div>
  );
};

export default Animation;
