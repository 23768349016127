import { useMemo } from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs as MantineBreadcrumbs } from "@mantine/core";

import "./styles.sass";

const routes = [
  {
    path: "companies/:companyId",
    name: "Company Management",
    children: [
      {
        path: "surveys",
        name: "Survey List",
        children: [
          {
            path: ":surveyId",
            name: "Survey Results",
          },
        ],
      },
      {
        path: "team/:teamId",
        name: "Team Management",
        children: [
          {
            path: "surveys",
            name: "Survey List",
            children: [
              {
                path: ":surveyId",
                name: "Survey Results",
              },
            ],
          },
        ],
      },
    ],
  },
];

const classNames = {
  root: "breadcrumbs",
  breadcrumb: "breadcrumbs__item",
  separator: "breadcrumbs__separator",
};

const Breadcrumbs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const matchedRoutes = useMemo(
    () => matchRoutes(routes, location),
    [location]
  );

  return (
    <MantineBreadcrumbs classNames={classNames}>
      {matchedRoutes?.map(({ pathname, route }) => {
        const isActive = pathname === location.pathname;
        const { name } = route as { name: string };

        return (
          <span
            key={pathname}
            onClick={() =>
              !isActive &&
              navigate(pathname, { state: { from: location.pathname } })
            }
          >
            {name}
          </span>
        );
      })}
    </MantineBreadcrumbs>
  );
};

export default Breadcrumbs;
