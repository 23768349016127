import axios from "axios";

import { useRef, useState } from "react";
import { Button, Modal } from "@mantine/core";
import { useMutation, useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";

import EmailWhitelist from "./EmailWhitelist";
import EmailForm, { IEmailFormForwardRef } from "components/EmailForm";
import TeamForm from "components/Team/TeamForm/TeamForm";
import useDefaultErrorHandler from "hooks/useDefaultErrorHandler";
import ErrorLoading from "components/ErrorLoading";
import ColoredShape from "components/ColoredShape";
import BackToScreen from "components/BackToScreen";
import ModalConfirmTrigger from "components/Modals/ModalConfirmTrigger";
import Breadcrumbs from "components/Breadcrumbs";
import ModalConfirm, {
  IModalConfirmMethods,
} from "components/Modals/ModalConfirm";
import LoadingSkeleton from "components/LoadingSkeleton";

import { CompanyRole, TeamResponse, CompanyResponse } from "types/models";
import { queryClient } from "App";

import "./styles.sass";

const TeamManagment = () => {
  const navigation = useNavigate();
  const { onErrorWithTitle } = useDefaultErrorHandler();
  const params = useParams();
  const [editModalVisible, setEditModalVisible] = useState(false);
  const emailFormRef = useRef<IEmailFormForwardRef>(null);
  const unsavedEmailDataModalRef = useRef<IModalConfirmMethods>(null);

  const [whitelistModalVisible, setWhitelistModalVisible] = useState(false);

  const companyId = params.companyId;
  const teamId = params.teamId;

  const { isLoading, error, data } = useQuery<TeamResponse>(
    `team${teamId}`,
    async () => {
      const response = await axios.get<TeamResponse>(
        `/companies/${companyId}/teams/${teamId}`
      );
      return response.data;
    }
  );

  const { data: companyData } = useQuery<CompanyResponse>(
    `companies${companyId}`,
    async () => {
      const response = await axios.get<CompanyResponse>(
        `/companies/${companyId}`
      );
      return response.data;
    }
  );

  const companyEmails = companyData?.roles.map((role) => role.email);

  const team = data?.team;
  const roles = data?.roles || ([] as CompanyRole[]);

  const teamHasMember = roles.find((role) => role.userId);

  const addEmailToWhitelist = useMutation(
    (emails: string[]) => {
      return axios.post<string>(
        `/companies/${companyId}/teams/${teamId}/member`,
        { emails }
      );
    },
    {
      onSuccess: () => {
        setWhitelistModalVisible(false);
        queryClient.invalidateQueries(`team${teamId}`);
      },
      onError: onErrorWithTitle("Can not add user to whitelist"),
    }
  );

  const deleteTeamMuatation = useMutation(
    () => axios.delete<string>(`/companies/${companyId}/teams/${teamId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`team${teamId}`);
        navigation(-1);
      },
      onError: onErrorWithTitle("Can not remove team"),
    }
  );

  const makeALeader = useMutation(
    (email: string) => {
      return axios.post<string>(
        `/companies/${companyId}/teams/${teamId}/leader`,
        { email }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`team${teamId}`);
      },
      onError: onErrorWithTitle("Can not setup a leader"),
    }
  );

  const removeRole = useMutation(
    (roleId: string) => {
      return axios.delete<string>(`/role/${roleId}/remove`);
    },
    {
      onSuccess: () => {
        setWhitelistModalVisible(false);
        queryClient.invalidateQueries(`team${teamId}`);
      },
      onError: onErrorWithTitle("Can not remove role"),
    }
  );

  if (isLoading) return <LoadingSkeleton fullWidth />;

  if (error) return <ErrorLoading title="Can't load team info and roles" />;

  return (
    <div className="team-panel">
      <BackToScreen
        name="Company Management"
        path={`/companies/${companyId}`}
      />
      <Breadcrumbs />
      <div className="team-panel__header">
        <h1 className="team-panel__title">
          Team Managment
          <span className="team-panel__title__separator">{" - "}</span>
          <span className="team-panel__title__team-name">
            {team?.name}
            <ColoredShape
              className="team-panel__team-shape"
              color={team?.pointColor}
              shape={team?.pointShape}
            />
          </span>
        </h1>

        <div className="company-panel__buttons">
          <Button
            onClick={() => {
              navigation(`surveys`);
            }}
            className="team-panel__add-button"
            color="green"
            disabled={Boolean(!teamHasMember)}
          >
            Show Results
          </Button>
          <Button
            onClick={() => setEditModalVisible(true)}
            className="team-panel__add-button"
          >
            Edit
          </Button>
          <ModalConfirmTrigger
            modalMessage="Are you sure you want to remove this team?"
            onConfirm={() => {
              deleteTeamMuatation.mutate();
            }}
            renderTrigger={(setModalVisible) => (
              <Button
                color="red"
                onClick={() => setModalVisible(true)}
                className="team-panel__add-button"
              >
                Remove
              </Button>
            )}
          />
        </div>
      </div>
      <h3 className="team-panel__description">
        <span className="team-panel__description-header">Description:</span>
        {team?.description}
      </h3>
      <EmailWhitelist
        onRemove={removeRole.mutate}
        roles={roles}
        makeALeader={makeALeader.mutate}
      />

      <Button
        className="team-panel__add-user-button"
        onClick={() => setWhitelistModalVisible(true)}
      >
        Add user by email
      </Button>

      <Modal
        opened={whitelistModalVisible}
        onClose={() =>
          emailFormRef.current?.anyUnsavedData()
            ? unsavedEmailDataModalRef.current?.show()
            : setWhitelistModalVisible(false)
        }
        title="Add user to the team"
        size="xl"
      >
        <EmailForm
          ref={emailFormRef}
          companyEmails={companyEmails}
          denyEmailList={roles.map((role) => role.email) || []}
          onSubmit={(emails) => emails && addEmailToWhitelist.mutate(emails)}
        />
      </Modal>

      <Modal
        opened={editModalVisible}
        onClose={() => setEditModalVisible(false)}
        title="Update Team"
      >
        <TeamForm
          initialValues={team}
          onClose={() => setEditModalVisible(false)}
          companyId={companyId}
          teamId={teamId}
        />
      </Modal>

      <ModalConfirm
        onConfirm={() => setWhitelistModalVisible(false)}
        ref={unsavedEmailDataModalRef}
        modalMessage="Unsaved data will be lost, are you sure?"
      />
    </div>
  );
};

export default TeamManagment;
