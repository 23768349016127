import React, { useCallback, useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { each, filter, isEmpty, keys } from "lodash";
import { useQuery } from "react-query";
import axios, { AxiosError } from "axios";

import ColoredShape from "components/ColoredShape";
import Chart from "components/Chart";
import SurveyFinishedWrapper from "components/SurveyFinishedWrapper/SurveyFinishedWrapper";
import CategoryFilters from "components/Category/CategoryFilters";

import SingleCompanyResult from "./../SingleCompanyResult/SingleCompanyResult";

import {
  AdditionalData,
  ComplexRole,
  SurveyDetails,
  SurveyAnswers,
  CategoryResults,
  SimpleTeam,
} from "types/models";
import { Switch } from "components/Switch";
import { ProfileContext } from "routes";
import { ReactComponent as CircleIcon } from "assets/shapes/Circle.svg";

type ResultsPerCompany = {
  [key: string]: {
    role: ComplexRole;
    categoriesArray: CategoryResults[];
    team: boolean;
    hidden: boolean;
    teamInfo?: SimpleTeam;
  };
};

type Props = {
  surveyFinished: { _id: string; surveysAnswers: SurveyAnswers };
  survey?: SurveyDetails;
};

const SurveyResults = ({ surveyFinished, survey }: Props) => {
  const [categoriesToFilter, setCategoriesToFilter] = useState<string[]>([
    "all",
  ]);
  const [visibleData, setVisibleData] = useState<{ [key: string]: boolean }>(
    {}
  );
  const { profile } = useContext(ProfileContext);
  const [showMyResults, setShowMyResults] = useState(true);
  const [companiesResults, setCompaniesResult] = useState<ResultsPerCompany>(
    {}
  );
  const isUserInCompanyOrTeam = useMemo<boolean>(
    () =>
      !isEmpty(
        filter(profile?.privileges, ({ role }) => role !== "SUPER_ADMIN")
      ),
    [profile]
  );
  const [isDataSet, setIsDataSet] = useState<boolean>(isUserInCompanyOrTeam);
  const isCompaniesResultsEmpty = useMemo<boolean>(
    () => !isDataSet && isEmpty(companiesResults),
    [companiesResults, isDataSet]
  );
  const usersTeams = profile?.privileges.reduce<string[]>((prev, curr) => {
    const teamId = curr.team?._id;
    if (teamId) {
      if (!prev.find((el) => teamId === el)) {
        return [...prev, teamId];
      }
    }
    return prev;
  }, []);

  const { surveyId } = useParams();

  const { data: allSurveyResult } = useQuery<
    { [key: string]: CategoryResults },
    AxiosError
  >(
    `surveyResultsAll-${surveyId}`,
    async () => {
      const { data } = await axios.get<{ [key: string]: CategoryResults }>(
        `/survey-results/${surveyId}/companies`
      );
      return data;
    },
    { enabled: isCompaniesResultsEmpty }
  );

  const defaultSurveyResult = useMemo(
    () => Object.values(allSurveyResult || {}),
    [allSurveyResult]
  );

  const surveyResult = surveyFinished?.surveysAnswers?.[0].surveyResult;

  const additionalData = useMemo(() => {
    const tmp: AdditionalData[] = [];
    let visibility = {};

    if (!isCompaniesResultsEmpty) {
      each(keys(companiesResults), (companyOrTeamId) => {
        const dataAndRoleForCompany = companiesResults[companyOrTeamId];

        if (dataAndRoleForCompany.teamInfo) {
          tmp.push({
            categories: dataAndRoleForCompany.categoriesArray,
            pointColor: dataAndRoleForCompany.teamInfo.pointColor,
            pointShape: dataAndRoleForCompany.teamInfo.pointShape,
            _id: dataAndRoleForCompany.teamInfo.teamId,
            name: `${dataAndRoleForCompany.role.company.name} / ${dataAndRoleForCompany.teamInfo.teamName}`,
          });
        } else {
          tmp.push({
            categories: dataAndRoleForCompany.categoriesArray,
            pointColor: dataAndRoleForCompany.role.company.pointColor,
            pointShape: dataAndRoleForCompany.role.company.pointShape,
            _id: companyOrTeamId,
            name: dataAndRoleForCompany.role.company.name,
          });
        }

        visibility = {
          ...visibility,
          [companyOrTeamId]: !dataAndRoleForCompany.hidden,
        };
      });
    }
    setVisibleData(visibility);
    return tmp;
  }, [companiesResults, isCompaniesResultsEmpty]);

  const filteredAdditionalData = useMemo(() => {
    return additionalData.filter((element) => {
      return visibleData[element._id];
    });
  }, [additionalData, visibleData]);

  const setDataForCompany = useCallback(
    (role: ComplexRole) =>
      (
        companyId: string,
        categoriesArray: CategoryResults[],
        hidden: boolean
      ) => {
        setCompaniesResult((prev) => ({
          ...prev,
          [companyId]: { categoriesArray, role, team: false, hidden },
        }));
      },
    []
  );

  const setDataForTeam = useCallback(
    (role: ComplexRole) =>
      (
        _companyId: string,
        teamInfo: SimpleTeam,
        categoriesArray: CategoryResults[]
      ) => {
        setCompaniesResult((prev) => ({
          ...prev,
          [teamInfo.teamId]: {
            categoriesArray,
            role,
            team: true,
            hidden: !usersTeams?.find((el) => el === teamInfo.teamId),
            teamInfo,
          },
        }));
      },
    [usersTeams]
  );

  const surveyResults = useMemo(() => {
    const availableCategories = surveyResult.map((categoryResult) => ({
      value: categoryResult.categoryId,
      label: categoryResult.categoryTitle,
    }));
    return (
      <>
        <SurveyFinishedWrapper
          surveyTitle={survey?.title}
          description="Compare your results with the company, the world or other
          employees. To display the data on the chart, turn on the switch
          next to the category name."
        >
          <div className="results__legend">
            {isCompaniesResultsEmpty && (
              <div className="results__item">
                <div className="results__icon">
                  <ColoredShape shape="hexagon" color="#008cb4" />
                </div>
                <span className="results__item-name">Global</span>
              </div>
            )}
            <div className="results__item">
              <div className="results__icon">
                <CircleIcon stroke={"#32A89C"} />
              </div>
              <span className="results__item-name">You</span>
              <Switch value={showMyResults} setValue={setShowMyResults} />
            </div>
            {keys(visibleData).map((key) => {
              const singleVisibleData = additionalData.find(
                (el) => el._id === key
              );
              return (
                <div key={key} className="results__item">
                  <div className="results__icon">
                    <ColoredShape
                      shape={singleVisibleData?.pointShape}
                      color={singleVisibleData?.pointColor}
                    />
                  </div>
                  <span className="results__item-name">
                    {singleVisibleData?.name}
                  </span>
                  <Switch
                    value={!!visibleData[key]}
                    setValue={() =>
                      setVisibleData({
                        ...visibleData,
                        [key]: !visibleData[key],
                      })
                    }
                  />
                </div>
              );
            })}
          </div>
          <CategoryFilters
            availableCategories={availableCategories}
            categoriesToFilter={categoriesToFilter}
            setCategoriesToFilter={setCategoriesToFilter}
          />
          <Chart
            surveyResult={surveyResult}
            additionalData={filteredAdditionalData}
            categoriesToFilter={categoriesToFilter}
            showMe={showMyResults}
            defaultSurveyResult={defaultSurveyResult}
            showDefault={isCompaniesResultsEmpty}
          />
        </SurveyFinishedWrapper>
        {profile?.privileges.map((role) => (
          <React.Fragment key={role.roleId}>
            {surveyId && role.company?._id && (
              <SingleCompanyResult
                key={role.roleId}
                surveyId={surveyId}
                companyId={role.company?._id}
                setDataForCompany={setDataForCompany(role)}
                setDataForTeam={setDataForTeam(role)}
                setIsDataSet={setIsDataSet}
                teamId={role.team?._id}
                hidden
              />
            )}
          </React.Fragment>
        ))}
      </>
    );
  }, [
    surveyResult,
    survey?.title,
    isCompaniesResultsEmpty,
    showMyResults,
    visibleData,
    categoriesToFilter,
    filteredAdditionalData,
    defaultSurveyResult,
    profile?.privileges,
    additionalData,
    surveyId,
    setDataForTeam,
    setDataForCompany,
  ]);

  return surveyResults;
};

export default SurveyResults;
