import { each, isEmpty, times, toString } from "lodash";
import {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useWindowDimensions from "hooks/useWindowDimensions";
import {
  AdditionalData,
  CategoryResults,
  DefaultIcon,
  Shape,
  TrendResults,
} from "types/models";

import "./styles.sass";

interface IProps {
  surveyResult: CategoryResults[];
  defaultSurveyResult?: CategoryResults[];
  additionalData: AdditionalData[];
  showMe: boolean;
  defaultIconConfig?: DefaultIcon;
  showDefault?: boolean;
  categoriesToFilter?: string[];
}

const ROW_HEIGHT_PX = 80;

type TrendToDisplay = TrendResults & { categoryTitle: string };

const asTrends = (data: CategoryResults[], categoriesToFilter?: string[]) => {
  const tmpTrends: TrendToDisplay[] = [];
  data?.forEach?.((category) => {
    if (categoriesToFilter && categoriesToFilter[0] !== "all") {
      if (categoriesToFilter.includes(toString(category?.categoryId))) {
        category.avgTrends.forEach((trend) => {
          tmpTrends.push({ ...trend, categoryTitle: category.categoryTitle });
        });
      }
    } else {
      category.avgTrends.forEach((trend) => {
        tmpTrends.push({ ...trend, categoryTitle: category.categoryTitle });
      });
    }
  });
  return tmpTrends;
};

const Chart: FC<IProps> = ({
  additionalData,
  defaultSurveyResult,
  defaultIconConfig,
  showDefault,
  showMe,
  surveyResult,
  categoriesToFilter,
}) => {
  const { width: screenWidth } = useWindowDimensions();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const pixelRatio = window.devicePixelRatio;
  const ref = useRef<HTMLTableCellElement>(null);
  const canvas = useRef<HTMLCanvasElement>(null);
  const chart = useRef<HTMLDivElement>(null);
  const data = useRef<HTMLTableCellElement>(null);

  // responsive width and height
  useEffect(() => {
    setWidth(ref.current?.clientWidth || 0);
    setHeight(ref.current?.clientHeight || 0);
  }, [screenWidth, categoriesToFilter]); //Important! need to be here to recompute height when new filters applied

  const displayWidth = Math.floor(pixelRatio * width);
  const renderingAreaWidth = displayWidth - 50;
  const displayHeight = Math.floor(pixelRatio * height);
  const rowHeight = Math.floor(pixelRatio * ROW_HEIGHT_PX);

  const style = useMemo(() => {
    return { width, height };
  }, [height, width]);

  const surveyResultsAfterFilteringCategories = useMemo<
    CategoryResults[]
  >(() => {
    if (categoriesToFilter) {
      if (categoriesToFilter[0] === "all") {
        return surveyResult;
      }

      return surveyResult.filter((categoryResults) =>
        categoriesToFilter?.includes(categoryResults.categoryId)
      );
    }
    return surveyResult;
  }, [surveyResult, categoriesToFilter]);

  const userMappedTrendsData = useMemo<TrendToDisplay[]>(() => {
    return asTrends(surveyResultsAfterFilteringCategories, categoriesToFilter);
  }, [categoriesToFilter, surveyResultsAfterFilteringCategories]);

  const defaultMappedTrendsData = useMemo<TrendToDisplay[] | undefined>(() => {
    if (defaultSurveyResult) {
      return asTrends(defaultSurveyResult, categoriesToFilter);
    }
  }, [categoriesToFilter, defaultSurveyResult]);

  const numberOfRows = userMappedTrendsData.length;

  const renderResults = useCallback(
    (
      resultData: TrendToDisplay[],
      ctx: CanvasRenderingContext2D,
      color: string,
      shape?: Shape
    ) => {
      const dotsPositions: { x: number; y: number }[] = [];
      //position of dots
      if (resultData.length !== numberOfRows) {
        return;
      }

      times(numberOfRows, (time) => {
        const positionOfLine = rowHeight / 2 + time * rowHeight;
        const result = resultData[time];
        let dotPosition =
          (renderingAreaWidth / 4) * ((result.avgTrendAnswer || 3) - 1);

        //fix for edges of canvas
        dotPosition = dotPosition + 25;
        dotsPositions.push({ x: dotPosition, y: positionOfLine });
      });

      //connections between shapes
      ctx.beginPath();
      ctx.moveTo(dotsPositions[0].x, dotsPositions[0].y);
      ctx.lineWidth = 3;
      ctx.strokeStyle = color || "#c99284";
      dotsPositions.forEach((point) => {
        ctx.lineTo(point.x, point.y);
      });
      ctx.stroke();
      ctx.closePath();

      times(numberOfRows, (time) => {
        const positionOfLine = rowHeight / 2 + time * rowHeight;
        ctx.beginPath();
        const result = resultData[time];
        let dotPosition =
          (renderingAreaWidth / 4) * ((result.avgTrendAnswer || 3) - 1);

        //fix for edges of canvas
        dotPosition = dotPosition + 25;

        ctx.fillStyle = "#121212";
        switch (shape) {
          case "circle":
            ctx.arc(dotPosition, positionOfLine, 11, 0, 2 * Math.PI, true);
            break;
          case "triangle":
            ctx.moveTo(dotPosition, positionOfLine - 15);
            ctx.lineTo(dotPosition + 18, positionOfLine + 15);
            ctx.lineTo(dotPosition - 18, positionOfLine + 15);
            break;
          case "trapeze":
            ctx.moveTo(dotPosition, positionOfLine - 15);
            ctx.lineTo(dotPosition + 15, positionOfLine);
            ctx.lineTo(dotPosition, positionOfLine + 15);
            ctx.lineTo(dotPosition - 15, positionOfLine);
            break;
          case "hexagon":
            ctx.moveTo(dotPosition - 7.5, positionOfLine - 12.5);
            ctx.lineTo(dotPosition + 7.5, positionOfLine - 12.5);
            ctx.lineTo(dotPosition + 15, positionOfLine);
            ctx.lineTo(dotPosition + 7.5, positionOfLine + 12.5);
            ctx.lineTo(dotPosition - 7.5, positionOfLine + 12.5);
            ctx.lineTo(dotPosition - 15, positionOfLine);
            break;
          default:
            ctx.rect(dotPosition - 12, positionOfLine - 10, 20, 20);
        }
        ctx.closePath();
        ctx.fill();
        ctx.lineWidth = 8;
        ctx.strokeStyle = color || "#c99284";
        ctx.stroke();
      });
    },
    [numberOfRows, rowHeight, renderingAreaWidth]
  );

  useLayoutEffect(() => {
    const ctx = canvas.current?.getContext?.("2d");
    if (!ctx) {
      return;
    }
    ctx.clearRect(0, 0, displayWidth, displayHeight);
    // horizontal lines
    times(numberOfRows, (time) => {
      const positionOfLine = rowHeight / 2 + time * rowHeight;
      ctx.beginPath();
      ctx.moveTo(0, positionOfLine);
      ctx.lineTo(displayWidth, positionOfLine);
      ctx.lineWidth = 2;
      ctx.strokeStyle = "#FFFFFF1A";
      ctx.stroke();
      ctx.closePath();
      ctx.beginPath();
      ctx.arc(displayWidth / 2, positionOfLine, 4, 0, 2 * Math.PI, true);
      ctx.lineWidth = 5;
      ctx.strokeStyle = "#FFFFFF1A";
      ctx.stroke();
      ctx.fillStyle = "#FFFFFF1A";
      ctx.fill();
      ctx.closePath();
    });
    //
    if (
      showDefault &&
      defaultMappedTrendsData &&
      !isEmpty(defaultMappedTrendsData)
    ) {
      renderResults(defaultMappedTrendsData, ctx, "#008cb4", "hexagon");
    }

    if (showMe) {
      renderResults(
        userMappedTrendsData,
        ctx,
        defaultIconConfig?.pointColor || "#32A89C",
        defaultIconConfig?.pointShape || "circle"
      );
    }

    each(additionalData, ({ categories, pointColor, pointShape }) => {
      renderResults(
        asTrends(categories, categoriesToFilter),
        ctx,
        pointColor,
        pointShape
      );
    });
  }, [
    width,
    height,
    displayWidth,
    displayHeight,
    numberOfRows,
    rowHeight,
    userMappedTrendsData,
    additionalData,
    renderResults,
    showMe,
    defaultIconConfig?.pointColor,
    defaultIconConfig?.pointShape,
    defaultMappedTrendsData,
    showDefault,
    categoriesToFilter,
  ]);

  const resultChart = useMemo(() => {
    const chartWidth = chart.current?.clientWidth || 1;
    const labelWidth = window.innerWidth < 576 ? 100 : 160;

    return (
      <div
        style={{
          width: chartWidth - 2 * labelWidth,
          height: numberOfRows * ROW_HEIGHT_PX,
        }}
      >
        <canvas
          ref={canvas}
          width={displayWidth}
          height={displayHeight}
          style={style}
        />
      </div>
    );
  }, [displayHeight, displayWidth, numberOfRows, style]);

  const renderRow = (item: TrendToDisplay, index: number) => {
    const firstRow = index === 0;
    return (
      <tr className="chart__row" key={index}>
        <td className="chart__left" ref={data}>
          <span className="chart__trend" title={item.trendSecondary}>
            {item.trendSecondary}
          </span>
        </td>
        {firstRow && (
          <td className="tg-0" rowSpan={numberOfRows} ref={ref}>
            {resultChart}
          </td>
        )}
        <td className="chart__right">
          <span className="chart__trend" title={item.trendPrimary}>
            {item.trendPrimary}
          </span>
        </td>
      </tr>
    );
  };

  return (
    <div className="chart" ref={chart}>
      <table className="chart__table">
        <thead>
          {userMappedTrendsData.map((item: TrendToDisplay, index: number) => {
            return renderRow(item, index);
          })}
        </thead>
      </table>
    </div>
  );
};

export default Chart;
