import classNames from "classnames";
import axios from "axios";

import { useState, useMemo, useEffect, useContext, useCallback } from "react";
import { useMutation } from "react-query";
import { toNumber } from "lodash";
import { Progress } from "@mantine/core";
import { motion, AnimatePresence } from "framer-motion";

import useDefaultErrorHandler from "hooks/useDefaultErrorHandler";
import ModalConfirmTrigger from "components/Modals/ModalConfirmTrigger";
import OnboardingProfileModal from "components/OnboardingProfileModal";
import { ProfileContext } from "routes";

import { Answer, QuestionWithAnswer } from "types/models";
import { ReactComponent as RightArrow } from "assets/RightArrow.svg";
import { ReactComponent as LeftArrowGray } from "assets/LeftArrowGray.svg";

import "./styles.sass";

const OPTIONS = [
  { value: "1", label: "strongly disagree" },
  { value: "2", label: "disagree" },
  { value: "3", label: "neutral" },
  { value: "4", label: "agree" },
  { value: "5", label: "strongly agree" },
];

export default function QuestionResponse({
  refetch,
  questionsWithAnswers,
  disabled,
  surveyId,
  finishSurvey,
  surveyTitle,
}: {
  refetch: () => void;
  questionsWithAnswers: QuestionWithAnswer[];
  disabled: boolean;
  surveyId: string;
  finishSurvey: () => void;
  surveyTitle?: string;
}) {
  const [questionIndex, setQuestionIndex] = useState(0);
  const currentQuestion = questionsWithAnswers[questionIndex];
  const numberOfQuestions = questionsWithAnswers.length;
  const [liveValue, setLiveValue] = useState(currentQuestion.answer?.value);
  const [animationConfig, setAnimationConfig] = useState({
    initial: { x: 100 },
    exit: { x: -100 },
  });
  const [visibleProfileModal, setVisibleProfileModal] = useState(false);
  const { profile } = useContext(ProfileContext);

  useEffect(() => {
    if (Object.keys(profile?.userDetails || {}).length < 7) {
      setVisibleProfileModal(true);
    }
  }, [profile]);

  const progress = useMemo(() => {
    const x = questionIndex + 1;
    const result = (x / numberOfQuestions) * 100;

    return result;
  }, [questionIndex, numberOfQuestions]);

  const dotPosition = (value: number) => {
    const x = value - 1;
    const result = (x / (OPTIONS.length - 1)) * 100;

    return result;
  };

  const changeQuestion = useCallback(
    (value: number) => {
      setQuestionIndex(value);
      setLiveValue(questionsWithAnswers[value].answer?.value);
    },
    [questionsWithAnswers]
  );

  useEffect(() => {
    const indexOfFirstQuestionWithoutAnswer = questionsWithAnswers?.findIndex(
      (item) => !item.answer || !item.answer.value
    );

    if (indexOfFirstQuestionWithoutAnswer > -1) {
      changeQuestion(indexOfFirstQuestionWithoutAnswer);
    } else {
      changeQuestion(numberOfQuestions - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { onErrorWithTitle } = useDefaultErrorHandler();

  const responseMutation = useMutation(
    async (surveyResponse: Answer) => {
      return axios.post(`/survey-answers/${surveyId}`, surveyResponse);
    },
    {
      onSuccess: () => {
        refetch();
      },
      onError: onErrorWithTitle("Can not save answer"),
    }
  );

  const setAndSaveNewValue = useCallback(
    (val: string) => {
      setLiveValue(toNumber(val));
      responseMutation.mutate({
        questionId: currentQuestion.question._id,
        value: toNumber(val),
      });
      if (
        !questionsWithAnswers[questionIndex].answer?.value &&
        questionIndex + 1 < numberOfQuestions
      ) {
        changeQuestion(questionIndex + 1);
      }
    },
    [
      changeQuestion,
      currentQuestion.question._id,
      numberOfQuestions,
      questionIndex,
      questionsWithAnswers,
      responseMutation,
    ]
  );

  const previousButton = () => {
    return (
      <button
        className="response__button --previous"
        onClick={async () => {
          await setAnimationConfig({ initial: { x: -100 }, exit: { x: 100 } });
          changeQuestion(questionIndex - 1);
        }}
      >
        <LeftArrowGray className="left-arrow" /> Previous
      </button>
    );
  };

  const nextButton = () => {
    return (
      <button
        className="response__button --next"
        onClick={async () => {
          await setAnimationConfig({ initial: { x: 100 }, exit: { x: -100 } });
          changeQuestion(questionIndex + 1);
        }}
      >
        Next <RightArrow className="right-arrow" />
      </button>
    );
  };

  const submitButton = () => {
    return (
      <ModalConfirmTrigger
        modalMessage="Are you sure you want to finish this survey?"
        onConfirm={() => {
          finishSurvey();
        }}
        renderTrigger={(setModalVisible) => (
          <button
            onClick={() => {
              setModalVisible(true);
            }}
            disabled={disabled}
            className="response__button--submit"
          >
            Submit responses
          </button>
        )}
      />
    );
  };

  const nextStep = () => {
    return questionIndex + 1 < numberOfQuestions
      ? nextButton()
      : submitButton();
  };

  const renderOption = useCallback(
    (option: { value: string; label: string }) => (
      <label
        className={`response__wrapper offset-${dotPosition(
          toNumber(option.value)
        )}`}
        htmlFor={option.value}
        key={option.value}
      >
        <span className="response__label">{option.label}</span>
        <div
          className={classNames("response__input", {
            "--checked": toNumber(option.value) === liveValue,
          })}
        />
        <input
          style={{ display: "none" }}
          name="input"
          value={option.value}
          id={option.value}
          type="checkbox"
          onChange={() =>
            toNumber(option.value) === liveValue
              ? setAndSaveNewValue("")
              : setAndSaveNewValue(option.value)
          }
        />
      </label>
    ),
    [liveValue, setAndSaveNewValue]
  );

  return (
    <>
      <div className="response">
        <div className="response__header">
          <h4 className="response__name">{surveyTitle}</h4>
          <h4 className="response__index">
            {questionIndex + 1}/{numberOfQuestions}
          </h4>
        </div>
        <Progress
          size="sm"
          value={progress}
          color="#32A89C"
          style={{ backgroundColor: "#292929" }}
        />
        <AnimatePresence initial={false} exitBeforeEnter>
          <motion.div
            key={currentQuestion.question._id}
            initial={{ x: animationConfig.initial.x, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: animationConfig.exit.x, opacity: 0 }}
            transition={{ type: "tween", duration: 0.2 }}
          >
            <h3 className="response__title">
              {currentQuestion.question.title}
            </h3>
          </motion.div>
        </AnimatePresence>
        <div className="response__answers">
          {OPTIONS.map(renderOption)}
          {!!liveValue && (
            <div
              className={`response--checked offset-${dotPosition(liveValue)}`}
              onClick={() => {
                setAndSaveNewValue("");
              }}
            ></div>
          )}
        </div>
        <div className="response__nav">
          <div>{questionIndex > 0 && previousButton()}</div>
          <div>{!!liveValue && nextStep()}</div>
        </div>
      </div>
      <OnboardingProfileModal
        surveyId={surveyId}
        visibleProfileModal={visibleProfileModal}
        setVisibleProfileModal={setVisibleProfileModal}
      />
    </>
  );
}
