import axios from "axios";

import { useMemo, useEffect } from "react";
import { useQuery } from "react-query";
import { isArray, isEmpty } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ErrorLoading from "components/ErrorLoading";
import BackToScreen from "components/BackToScreen";
import Breadcrumbs from "components/Breadcrumbs";
import ResponseItem from "components/ResponseItem";
import LoadingSkeleton from "components/LoadingSkeleton";

import { Survey, CompanyResponse, TeamResponse } from "types/models";
import { ReactComponent as BGIcons } from "assets/BGIcons.svg";

import "./styles.sass";

const SurveyList = () => {
  const { companyId, teamId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoading, error, data } = useQuery<Survey[]>(
    "surveysAllPublic",
    async () => {
      const response = await axios.get<Survey[]>("/surveys/public");
      return response.data;
    }
  );

  const { data: company, isLoading: isLoadingCompany } =
    useQuery<CompanyResponse>(
      `companies${companyId}`,
      async () => {
        const response = await axios.get<CompanyResponse>(
          `/companies/${companyId}`
        );
        return response.data;
      },
      { enabled: !!companyId }
    );

  const { data: team, isLoading: isLoadingTeam } = useQuery<TeamResponse>(
    `team${teamId}`,
    async () => {
      const response = await axios.get<TeamResponse>(
        `/companies/${companyId}/teams/${teamId}`
      );
      return response.data;
    },
    { enabled: !!teamId && !!companyId }
  );

  const navigateUrl = useMemo(() => {
    if (teamId) {
      return `/companies/${companyId}/team/${teamId}/surveys/`;
    }
    if (companyId) {
      return `/companies/${companyId}/surveys/`;
    }
    return `/survey/`;
  }, [teamId, companyId]);

  const header = useMemo(() => {
    if (teamId) {
      if (isLoadingTeam) {
        return "Loading...";
      }
      return `Survey Results - ${team?.team.name}`;
    }
    if (companyId) {
      if (isLoadingCompany) {
        return "Loading...";
      }
      return `Survey Results - ${company?.company.name}`;
    }
    return "Survey List";
  }, [
    teamId,
    companyId,
    isLoadingTeam,
    team?.team.name,
    isLoadingCompany,
    company?.company.name,
  ]);

  useEffect(() => {
    if (!(location.state as { from: string })?.from) {
      if (!isLoading && data?.length === 1) {
        navigate(navigateUrl + data[0]._id, { replace: true });
      }
    }
  }, [data, navigateUrl, isLoading, location, navigate]);

  const content = useMemo(() => {
    if (isLoading) return <LoadingSkeleton />;

    if (error) return <ErrorLoading title="Can't load responses" />;

    const mappedData = data?.map((el) => ({
      survey: el,
    }));

    return (
      isArray(mappedData) &&
      (isEmpty(mappedData) ? (
        <h3 className="responses__empty">No available surveys yet</h3>
      ) : (
        <ul className="responses__surveys">
          {mappedData?.map((item) => (
            <ResponseItem
              key={item.survey._id}
              item={item}
              navigateUrl={navigateUrl}
            />
          ))}
        </ul>
      ))
    );
  }, [data, error, isLoading, navigateUrl]);

  const backToScreen = useMemo(() => {
    if (teamId) {
      return "Team Management";
    }
    if (companyId) {
      return "Company Management";
    }
    return "Login";
  }, [teamId, companyId]);

  const backToScreenPath = useMemo(() => {
    if (teamId) {
      return `/companies/${companyId}/team/${teamId}`;
    }
    if (companyId) {
      return `/companies/${companyId}`;
    }
    return "/landing";
  }, [teamId, companyId]);

  return (
    <div className="responses">
      <BackToScreen name={backToScreen} path={backToScreenPath} />
      <Breadcrumbs />
      <h1 className="responses__headline">{header}</h1>
      {content}
      <div className="svg-background">
        <BGIcons />
      </div>
    </div>
  );
};

export default SurveyList;
