import axios, { AxiosError } from "axios";
import { values } from "lodash";
import { FC, useEffect } from "react";
import { useQuery } from "react-query";
import { SimpleTeam, CategoryResults } from "types/models";

interface IProps {
  companyId: string;
  surveyId: string;
  setDataForTeam: (
    companyId: string,
    teamInfo: SimpleTeam,
    data: CategoryResults[],
    hidden: boolean
  ) => void;
  setIsDataSet: (isDataSet: boolean) => void;
  teamInfo: SimpleTeam;
  hidden?: boolean;
}

const SingleTeamResult: FC<IProps> = ({
  companyId,
  surveyId,
  setDataForTeam,
  teamInfo,
  hidden = false,
  setIsDataSet,
}) => {
  const { data } = useQuery<{ [key: string]: CategoryResults }, AxiosError>(
    `surveyResultsAll-${surveyId}-${companyId}-${teamInfo.teamId}`,
    async () => {
      const { data: resultData } = await axios.get<{
        [key: string]: CategoryResults;
      }>(
        `/survey-results/${surveyId}/companies/${companyId}/teams/${teamInfo.teamId}`
      );
      return resultData;
    }
  );

  useEffect(() => {
    if (data) {
      setDataForTeam(companyId, teamInfo, values(data), hidden);
      setIsDataSet(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return null;
};

export default SingleTeamResult;
