import { forwardRef, useImperativeHandle, useState } from "react";
import { Button } from "@mantine/core";
import ModalWrapper from "../ModalWrapper";

type Props = {
  onConfirm: () => void;
  modalMessage: string;
};

export type IModalConfirmMethods = {
  show: () => void;
};

const ModalConfirm = forwardRef<IModalConfirmMethods, Props>(
  ({ onConfirm, modalMessage }, ref) => {
    const [visible, setVisible] = useState(false);

    useImperativeHandle(
      ref,
      () => ({
        show: () => setVisible(true),
      }),
      []
    );

    return (
      <ModalWrapper
        modalMessage={modalMessage}
        modalVisible={visible}
        setModalVisible={setVisible}
        data-testid="modal-wrapper"
      >
        <Button
          className="modal__button modal__confirm"
          onClick={() => {
            onConfirm();
            setVisible(false);
          }}
        >
          Confirm
        </Button>
        <Button
          className="modal__button modal__cancel"
          onClick={() => setVisible(false)}
        >
          Cancel
        </Button>
      </ModalWrapper>
    );
  }
);

export default ModalConfirm;
