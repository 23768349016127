import { useState } from "react";
import Cookies from "js-cookie";

import { ReactComponent as XCircle } from "assets/XCircle.svg";

import "./styles.sass";

const cookiePolicyUrl = process.env.COOKIE_POLICY_URL;

const CookiePolicy = () => {
  const [isVisible, setIsVisible] = useState(Cookies.get("cookiePolicy"));

  if (isVisible) return null;

  return (
    <div className="cookie-policy-banner" data-testid="cookie-policy-container">
      <div className="cookie-policy-banner__content">
        <div>
          We use cookies to make your exploring a better experience. Please see
          our&nbsp;
          <a href={cookiePolicyUrl} target="_blank" rel="noreferrer">
            cookie policy.
          </a>
        </div>
        <button
          onClick={() => {
            Cookies.set("cookiePolicy", "true");
            setIsVisible(Cookies.get("cookiePolicy"));
          }}
        >
          <XCircle />
        </button>
      </div>
    </div>
  );
};

export default CookiePolicy;
