import axios from "axios";

import { Button, Group, Modal } from "@mantine/core";
import { useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Company, CompanyRole, CompanyResponse } from "types/models";
import { useNavigate, useParams } from "react-router-dom";

import BackToScreen from "components/BackToScreen";
import CompanyForm from "components/Company/CompanyForm";
import EmailWhitelist from "components/EmailWhitelist/EmailWhitelist";
import EmailForm, { IEmailFormForwardRef } from "components/EmailForm";
import TeamForm from "components/Team/TeamForm/TeamForm";
import ColoredShape from "components/ColoredShape";
import useDefaultErrorHandler from "hooks/useDefaultErrorHandler";
import ErrorLoading from "components/ErrorLoading";
import Breadcrumbs from "components/Breadcrumbs";
import ModalConfirm, {
  IModalConfirmMethods,
} from "components/Modals/ModalConfirm";
import LoadingSkeleton from "components/LoadingSkeleton";

import { queryClient } from "App";

import "./styles.sass";

const CompanyManagement = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [addTeamModalVisible, setTeamModalVisible] = useState(false);
  const { onErrorWithTitle } = useDefaultErrorHandler();
  const emailFormRef = useRef<IEmailFormForwardRef>(null);
  const [whitelistModalVisible, setWhitelistModalVisible] = useState(false);
  const unsavedEmailDataModalRef = useRef<IModalConfirmMethods>(null);

  const { isLoading, error, data } = useQuery<CompanyResponse>(
    `companies${companyId}`,
    async () => {
      const response = await axios.get<CompanyResponse>(
        `/companies/${companyId}`
      );
      return response.data;
    }
  );

  const company = data?.company;
  const companyFormInitialValues: Partial<Company> = {
    ...company,
    pointShape: company?.pointShape || "square",
    pointColor: company?.pointColor || "#ffffff",
  };
  const roles = data?.roles || ([] as CompanyRole[]);

  const addEmailToWhitelist = useMutation(
    (emails: string[]) => {
      return axios.post<string>(`/companies/${companyId}/member`, {
        emails,
      });
    },
    {
      onSuccess: () => {
        setWhitelistModalVisible(false);
        queryClient.invalidateQueries(`companies${companyId}`);
      },
      onError: onErrorWithTitle("Can not add member"),
    }
  );

  const removeRole = useMutation(
    (roleId: string) => {
      return axios.delete<string>(`/role/${roleId}/remove`);
    },
    {
      onSuccess: () => {
        setWhitelistModalVisible(false);
        queryClient.invalidateQueries(`companies${companyId}`);
      },
      onError: onErrorWithTitle("Can not remove role"),
    }
  );

  if (isLoading) return <LoadingSkeleton fullWidth />;

  if (error) {
    return <ErrorLoading title="Can't load company data" />;
  }

  return (
    <div className="company-panel">
      <BackToScreen name="Your Companies and Roles" path="/manage" />
      <Breadcrumbs />
      <div className="company-panel__header">
        <h1 className="company-panel__title">
          Company Management
          <span className="company-panel__title__separator">{" - "}</span>
          <span className="company-panel__title__company-name">
            {company?.name}
            <ColoredShape
              className="company-panel__company-shape"
              color={company?.pointColor}
              shape={company?.pointShape}
            />
          </span>
        </h1>
        <div className="company-panel__buttons">
          <Button
            onClick={() => {
              navigate(`surveys`);
            }}
            className="company-panel__add-button"
            color="green"
          >
            Show Results
          </Button>
          <Button
            onClick={() => setEditModalVisible(true)}
            className="company-panel__add-button"
          >
            Edit
          </Button>
        </div>
      </div>
      <h3 className="company-panel__description">
        <span className="company-panel__description-header">Description: </span>
        {company?.description}
      </h3>
      {company?.domain && (
        <h3 className="company-panel__domain">
          <span className="company-panel__domain-header">Domain:</span>{" "}
          {company?.domain}
        </h3>
      )}
      <EmailWhitelist
        onRemove={removeRole.mutate}
        roles={roles}
        teams={company?.teams}
      />
      <Button
        className="company-panel__add-user-button"
        onClick={() => setWhitelistModalVisible(true)}
      >
        Add user by email
      </Button>

      <Group className="teams">
        <h2> Teams </h2>
        <Button onClick={() => setTeamModalVisible(true)}>
          Create new team
        </Button>
      </Group>
      <div className="company-panel__team-buttons">
        {company?.teams?.map((team) => (
          <Button
            key={team._id}
            onClick={() => navigate(`team/${team._id}`)}
            color="yellow"
            className="company-panel__team-button"
          >
            {team.name}
          </Button>
        ))}
      </div>

      <Modal
        opened={editModalVisible}
        onClose={() => setEditModalVisible(false)}
        title={company?._id ? "Update Company" : "Create Company"}
      >
        <CompanyForm
          initialValues={companyFormInitialValues}
          onClose={() => setEditModalVisible(false)}
        />
      </Modal>

      <Modal
        opened={whitelistModalVisible}
        onClose={() =>
          emailFormRef.current?.anyUnsavedData()
            ? unsavedEmailDataModalRef.current?.show()
            : setWhitelistModalVisible(false)
        }
        title="Add user to the company"
        size="xl"
      >
        <EmailForm
          ref={emailFormRef}
          denyEmailList={roles.map((role) => role.email) || []}
          onSubmit={(emails) => emails && addEmailToWhitelist.mutate(emails)}
        />
      </Modal>

      <Modal
        opened={addTeamModalVisible}
        onClose={() => setTeamModalVisible(false)}
        title="Create Team"
      >
        <TeamForm
          onClose={() => setTeamModalVisible(false)}
          companyId={companyId}
        />
      </Modal>

      <ModalConfirm
        onConfirm={() => setWhitelistModalVisible(false)}
        ref={unsavedEmailDataModalRef}
        modalMessage="Unsaved data will be lost, are you sure?"
      />
    </div>
  );
};

export default CompanyManagement;
