import classnames from "classnames";
import { FC } from "react";

import "./styles.sass";

interface IProps {
  value: boolean;
  setValue: (newVal: boolean) => void;
  disabled?: boolean;
}

export const Switch: FC<IProps> = ({ value, setValue, disabled }) => {
  return (
    <div
      className={classnames("switch", { "switch--active": value && !disabled })}
      onClick={() => {
        if (!disabled) setValue(!value);
      }}
      data-testid="switch"
    >
      <div className="switch__point"></div>
    </div>
  );
};
