import axios from "axios";
import { useContext, useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { isEmpty, reduce, omitBy } from "lodash";
import { useNotifications } from "@mantine/notifications";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";

import ProfileSelect from "./ProfileSelect";
import useDefaultErrorHandler from "hooks/useDefaultErrorHandler";
import ErrorLoading from "components/ErrorLoading";
import BackToScreen from "components/BackToScreen";
import LoadingSkeleton from "components/LoadingSkeleton";

import { FormData, Filter } from "types/models";
import { ReactComponent as BGIcons } from "assets/BGIcons.svg";
import { ReactComponent as ProfileCircle } from "assets/ProfileCircle.svg";
import { ReactComponent as ProfileCircleFilled } from "assets/ProfileCircleFilled.svg";
import { ProfileContext } from "routes";

import "./styles.sass";

const ProfileDetails = () => {
  const { profile, invalidateProfile } = useContext(ProfileContext);
  const { showNotification } = useNotifications();
  const { onErrorWithTitle } = useDefaultErrorHandler();

  const location = useLocation();
  const surveyId = (location.state as { surveyId: string })?.surveyId;

  const {
    isLoading,
    error,
    data: filtersData,
  } = useQuery<Filter[]>("filtersAll", async () => {
    const response = await axios.get<Filter[]>("/filters");
    return response.data;
  });

  const updateMutation = useMutation(
    async (data: FormData) => {
      return axios.post(`/users/details`, data);
    },
    {
      onSuccess: () => {
        showNotification({
          color: "blue",
          title: "Success!",
          message: "Profile update is successful",
        });
        invalidateProfile();
      },
      onError: onErrorWithTitle("Can not create profile data"),
    }
  );

  const initialValues = reduce(
    filtersData || [],
    (result: FormData, filter: Filter) => {
      const valueFromProfile = profile?.userDetails?.[filter.filterPath];
      result[filter.filterPath] = valueFromProfile || "";
      return result;
    },
    {} as FormData
  );

  const {
    handleSubmit,
    setFieldValue,
    values: formValues,
  } = useFormik<FormData>({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      const valuesWithoutEmpties = omitBy(values, isEmpty);
      updateMutation.mutate(valuesWithoutEmpties);
    },
  });

  const progress = useMemo(() => {
    if (filtersData && profile) {
      const x = Object.keys(profile?.userDetails || {}).length;
      const result = (x / filtersData?.length) * 100 + "%";

      return result;
    }
  }, [filtersData, profile]);

  const content = useMemo(() => {
    if (isLoading) return <LoadingSkeleton />;

    if (error) return <ErrorLoading title="Can't load filters" />;

    if (!filtersData || isEmpty(filtersData))
      return <h3 className="profile__empty">No available filters</h3>;

    return (
      <div className="profile__wrapper">
        <div className="profile__progress">
          <ProfileCircle className="profile__circle" />
          <div
            style={{ height: `${progress}` }}
            className="profile__circle-wrapper"
          >
            <ProfileCircleFilled className="profile__circle-filled" />
          </div>
        </div>
        <ul className="profile__details">
          {filtersData.map((item: Filter) => (
            <ProfileSelect
              key={item._id}
              item={item}
              handleSubmit={handleSubmit}
              setFieldValue={setFieldValue}
              values={formValues}
            />
          ))}
        </ul>
      </div>
    );
  }, [
    isLoading,
    error,
    filtersData,
    progress,
    handleSubmit,
    setFieldValue,
    formValues,
  ]);

  return (
    <div className="profile">
      <h1 className="profile__headline">Your profile</h1>
      {content}
      <div className="svg-background">
        <BGIcons />
      </div>
      {progress === "100%" && (
        <div className="profile__footer">
          <span className="profile__message">
            {surveyId
              ? "Good job! Now you are ready to start survey!"
              : "Good job! Now you can check what surveys are waiting for you!"}
          </span>
          <div className="profile__back">
            {surveyId ? (
              <BackToScreen path={`/response/${surveyId}`} name="survey" />
            ) : (
              <BackToScreen name="Dashboard" />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileDetails;
