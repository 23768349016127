import { uniq } from "lodash";
import React, { FC } from "react";
import SingleTeamMemberResults from "./SingleTeamMemberResults";
import { CompanyRole, FilterSets, TeamResponse } from "types/models";

import "./styles.sass";

interface IProps {
  teamResponse: TeamResponse;
  setFilterSets: React.Dispatch<React.SetStateAction<FilterSets>>;
  companyId: string;
  surveyId: string;
  filterSets: FilterSets;
}

export const TeamMemberFilters: FC<IProps> = ({
  companyId,
  teamResponse,
  setFilterSets,
  surveyId,
  filterSets,
}) => {
  const roles = teamResponse?.roles || ([] as CompanyRole[]);
  const uniqTeamMembers = uniq(roles.map((role) => role.userId));

  const teamHasMember = roles.find((role) => role.userId);

  if (!teamHasMember) {
    return <span>Can't find team members.</span>;
  }

  return (
    <div className="team-results">
      <h3 className="team-results__header">
        {teamResponse?.team?.name} Team Members:
      </h3>
      {uniqTeamMembers.map((userId) => {
        const teamUserRole = roles.find((role) => role.userId === userId);
        return (
          teamUserRole?.userId && (
            <SingleTeamMemberResults
              key={userId}
              companyId={companyId}
              surveyId={surveyId}
              setFilterSets={setFilterSets}
              team={teamResponse.team}
              role={teamUserRole}
              filterSets={filterSets}
            />
          )
        );
      })}
    </div>
  );
};

export default TeamMemberFilters;
