import axios from "axios";
import { createContext } from "react";
import { useQuery } from "react-query";
import { Route, Routes } from "react-router-dom";
import { AppShell } from "@mantine/core";
import { useAuth0 } from "@auth0/auth0-react";

import Surveys from "routes/Surveys";
import AppHeader from "components/AppHeader";
import SurveyEdit from "components/SurveyEdit";
import Responses from "routes/Responses";
import ResponseEdit from "routes/Responses/Edit";
import RoleManagement from "routes/RoleManagement";
import SurveyResults from "components/SurveyResults";
import SurveyList from "components/SurveyList";
import ErrorLoading from "components/ErrorLoading";

import CompanyManagement from "routes/CompanyManagement";
import TeamManagement from "routes/TeamManagement";
import LoadingData from "components/LoadingData";
import ProfileDetails from "routes/ProfileDetails";
import UserManagement from "routes/UserManagement";
import ProtectedRoute from "routes/ProtectedRoute";

import { queryClient } from "App";
import { Profile } from "types/models";
import Results from "./routes/Results";

export interface ProfileContextInterface {
  profile: Profile | undefined;
  invalidateProfile: () => void;
}

export const ProfileContext = createContext<ProfileContextInterface>(
  {} as ProfileContextInterface
);

const AppRoutes = () => {
  const { user } = useAuth0();

  const auth0UserId = user?.sub?.split("|")[1];

  const { data, isLoading } = useQuery<Profile>(
    ["profileData", auth0UserId],
    async () => {
      const response = await axios.get<Profile>("/auth/profile");
      return response.data;
    }
  );

  const invalidateProfile = () => {
    queryClient.invalidateQueries(["profileData"]);
  };

  return (
    <ProfileContext.Provider value={{ profile: data, invalidateProfile }}>
      <AppShell
        // navbarOffsetBreakpoint controls when navbar should no longer be offset with padding-left
        navbarOffsetBreakpoint="sm"
        fixed
        header={<AppHeader />}
        className="app-shell"
      >
        {isLoading ? (
          <LoadingData />
        ) : (
          <Routes>
            <Route path="/take" element={<Responses />} />
            <Route path="/" element={<Results />} />
            <Route path="manage" element={<RoleManagement />} />
            <Route path="admin" element={<ProtectedRoute />}>
              <Route path="surveys" element={<Surveys />} />
              <Route path="survey/:id" element={<SurveyEdit />} />
              <Route path="users" element={<UserManagement />} />
            </Route>
            <Route path="profile" element={<ProfileDetails />} />
            <Route path="companies/:companyId" element={<ProtectedRoute />}>
              <Route index element={<CompanyManagement />} />
              <Route path="surveys" element={<SurveyList />} />
              <Route path="surveys/:surveyId" element={<SurveyResults />} />
            </Route>
            <Route
              path="companies/:companyId/team/:teamId"
              element={<ProtectedRoute />}
            >
              <Route index element={<TeamManagement />} />
              <Route path="surveys" element={<SurveyList />} />
              <Route path="surveys/:surveyId" element={<SurveyResults />} />
            </Route>

            <Route
              path="*"
              element={
                <ErrorLoading variant="info" title="There's nothing here" />
              }
            />
            <Route path="response/:surveyId" element={<ResponseEdit />} />
          </Routes>
        )}
      </AppShell>
    </ProfileContext.Provider>
  );
};

export default AppRoutes;
