import axios from "axios";

import { useState } from "react";
import { Button, TextInput, Title } from "@mantine/core";
import { useNotifications } from "@mantine/notifications";
import { useMutation } from "react-query";

import useDefaultErrorHandler from "hooks/useDefaultErrorHandler";
import ModalConfirmTrigger from "components/Modals/ModalConfirmTrigger";

import "./styles.sass";

export const UserManagement = () => {
  const [email, setEmail] = useState("");
  const { onErrorWithTitle } = useDefaultErrorHandler();
  const { showNotification } = useNotifications();

  const { mutate: deleteUser } = useMutation(
    async (userEmail: string) => {
      return axios.delete(`/users`, { data: { email: userEmail } });
    },
    {
      onSuccess: () => {
        showNotification({ title: "Removed!", message: email });
        setEmail("");
      },
      onError: onErrorWithTitle("Can not delete user"),
    }
  );

  const { mutate: makeSuperAdmin } = useMutation(
    async (userEmail: string) => {
      return axios.post("/role/superAdmin", { email: userEmail });
    },
    {
      onSuccess: () => {
        showNotification({
          title: "Role changed to SUPER_ADMIN!",
          message: email,
        });
        setEmail("");
      },
      onError: onErrorWithTitle("Can not make this user SUPER_ADMIN"),
    }
  );

  return (
    <div className="user-management">
      <Title className="user-management__title">Manage user by email</Title>
      <TextInput
        className="user-management__input"
        placeholder="Email"
        onChange={(e) => setEmail(e.currentTarget.value)}
        value={email}
      />
      <div className="user-management__buttons">
        <ModalConfirmTrigger
          modalMessage="Are you sure you want to delete this user?"
          onConfirm={() => {
            deleteUser(email);
          }}
          renderTrigger={(setModalVisible) => (
            <Button onClick={() => setModalVisible(true)} color="red">
              Remove User
            </Button>
          )}
        />
        <ModalConfirmTrigger
          modalMessage="Are you sure you want to make this user SUPER_ADMIN?"
          onConfirm={() => {
            makeSuperAdmin(email);
          }}
          renderTrigger={(setSuperAdminModalVisible) => (
            <Button
              onClick={() => setSuperAdminModalVisible(true)}
              color="blue"
            >
              Make SUPER_ADMIN
            </Button>
          )}
        />
      </div>
    </div>
  );
};

export default UserManagement;
