import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { uniqueId } from "lodash";
import { Button } from "@mantine/core";

import LoadingData from "../LoadingData";
import ResultsFilters from "./ResultsFilters";
import ColoredShape from "../ColoredShape";
import useDefaultErrorHandler from "hooks/useDefaultErrorHandler";
import { getRandomLightColor } from "utils/utils";
import {
  FiltersSet,
  FilterSets,
  ChangeFilterValue,
  DefaultIcon,
} from "types/models";

import "./styles.sass";

type Props = {
  filterSets: FilterSets;
  setFilterSets: React.Dispatch<React.SetStateAction<FilterSets>>;
  apiUrl?: string;
  isPublic?: boolean;
  showDefault?: boolean;
  defaultIconConfig?: DefaultIcon;
};

const FiltersSets = ({
  filterSets,
  setFilterSets,
  apiUrl,
  isPublic,
  showDefault,
  defaultIconConfig,
}: Props) => {
  const [newestFilterSetId, setNewestFilterSetId] = useState<string>();

  const { companyId, surveyId, teamId } = useParams();
  const { onErrorWithTitle } = useDefaultErrorHandler();

  const { isLoading: isLoadingFilters, data: filtersData } = useQuery<
    FiltersSet[],
    Error
  >(
    ["filterSets", surveyId, companyId],
    async () => {
      const { data } = await axios.get<FiltersSet[]>(
        `/filter-templates/${apiUrl}/filters`
      );
      return data;
    },
    { enabled: !isPublic, cacheTime: 0 }
  );

  useEffect(() => {
    if (!isPublic && filtersData) {
      const filtersDataResult = filtersData.reduce(
        (acc: FilterSets, filterSet: FiltersSet) => {
          acc[filterSet._id] = filterSet;
          return acc;
        },
        {}
      );
      setFilterSets((prevFilters) => ({
        ...prevFilters,
        ...filtersDataResult,
      }));
    }
  }, [isPublic, filtersData, setFilterSets]);

  const createMutation = useMutation(
    async (filtersSet: FiltersSet) => {
      return axios.post(`/filter-templates/${apiUrl}/filters`, filtersSet);
    },
    {
      onSuccess: (data) => {
        setNewestFilterSetId(data.data._id);
        setFilterSets({
          ...filterSets,
          [data.data._id]: data.data,
        });
      },
    }
  );

  const updateMutation = useMutation(
    async (filtersSet: FiltersSet) => {
      return axios.put(
        `/filter-templates/${apiUrl}/filters/${filtersSet._id}`,
        filtersSet
      );
    },
    {
      onError: onErrorWithTitle("Can not update filter set"),
    }
  );

  const deleteMutation = useMutation(
    async (filterSet: FiltersSet) => {
      return axios.delete(
        `/filter-templates/${apiUrl}/filters/${filterSet._id}`
      );
    },
    {
      onSuccess: (data, filterSet) => {
        const newFilterSurveyResult = { ...filterSets };
        delete newFilterSurveyResult[filterSet._id];
        setFilterSets(newFilterSurveyResult);
      },
    }
  );

  const createFilterSet = () => {
    const lightColor = getRandomLightColor();
    const id = uniqueId();

    const newFilterSet = {
      _id: id,
      name: `Filter Set`,
      pointColor: lightColor,
      pointShape: "trapeze" as const,
      categories: [],
      visible: true,
      filters: {},
    };

    if (!isPublic) {
      createMutation.mutate(newFilterSet);
    } else {
      setFilterSets({
        ...filterSets,
        [id]: {
          _id: id,
          name: `Filter Set ${id}`,
          pointColor: lightColor,
          pointShape: "trapeze",
          categories: [],
          visible: true,
          filters: {},
        },
      });
      setNewestFilterSetId(id);
    }
  };

  const updateFilterSet = (filterSetData: FiltersSet) => {
    setFilterSets((prevFilterSets: FilterSets) => {
      return { ...prevFilterSets, [filterSetData._id]: filterSetData };
    });
  };

  const changeFilterValue: ChangeFilterValue = (
    filterSetId,
    valueName,
    newValue
  ) => {
    const newFilterSet = {
      ...filterSets[filterSetId],
      [valueName]: newValue,
    };

    updateFilterSet(newFilterSet);
  };

  const handleSave = (filterSetData: FiltersSet, onSuccess: () => void) => {
    if (!isPublic) {
      updateMutation.mutate(filterSetData, {
        onSuccess: (data) => {
          updateFilterSet(data.data);
          onSuccess();
        },
      });
    } else {
      updateFilterSet(filterSetData);
      onSuccess();
    }
  };

  const handleDelete = (filterSet: FiltersSet) => {
    if (!isPublic) {
      deleteMutation.mutate(filterSet);
    } else {
      const newFilterSurveyResult = { ...filterSets };
      delete newFilterSurveyResult[filterSet._id];
      setFilterSets(newFilterSurveyResult);
    }
  };

  const handleVisible = (filterSet: FiltersSet) => {
    changeFilterValue(filterSet._id, "visible", !filterSet.visible);
    if (!isPublic) {
      updateMutation.mutate(
        {
          ...filterSet,
          visible: !filterSet.visible,
        },
        {
          onError: () => {
            changeFilterValue(filterSet._id, "visible", filterSet.visible);
          },
        }
      );
    }
  };

  const defaultIcon = useMemo(() => {
    return (
      <ColoredShape
        shape={defaultIconConfig?.pointShape || "circle"}
        color={defaultIconConfig?.pointColor || "#32A89C"}
      />
    );
  }, [defaultIconConfig]);

  const copyForDefaultResults = useMemo<string>(() => {
    if (isPublic) {
      return "Global Results";
    }
    if (companyId) {
      if (teamId) {
        return "Average team result";
      }
      return "Average company result";
    }
    return "Average";
  }, [companyId, isPublic, teamId]);

  if (!isPublic) {
    if (isLoadingFilters) {
      return <LoadingData title="Loading filters" />;
    }
  }

  return (
    <div className="filters">
      {showDefault && (
        <div className="filters__item">
          <div className="filters__icon">{defaultIcon}</div>
          <span className="filters__name filters__name--cursor-default">
            {copyForDefaultResults}
          </span>
        </div>
      )}
      {Object.values<FiltersSet>(filterSets)?.map(
        (filterSet) =>
          !filterSet?.user && (
            <ResultsFilters
              key={filterSet._id}
              currentFiltersValues={filterSet.filters}
              filterSet={filterSet}
              changeFilterValue={changeFilterValue}
              handleSave={handleSave}
              handleDelete={handleDelete}
              apiUrl={apiUrl}
              handleVisible={handleVisible}
              isModalVisible={newestFilterSetId === filterSet._id}
            />
          )
      )}
      <Button className="filters__new" onClick={createFilterSet}>
        Add new filterset
      </Button>
    </div>
  );
};

export default FiltersSets;
