import { times } from "lodash";
import classNames from "classnames";
import { Skeleton, SkeletonProps } from "@mantine/core";

import "./styles.sass";

interface Props extends SkeletonProps {
  count?: number;
  fullWidth?: boolean;
}

const LoadingSkeleton = ({ count = 3, fullWidth, ...props }: Props) => {
  return (
    <>
      {times(count, (index: number) => (
        <Skeleton
          key={index}
          className={classNames("skeleton", {
            "skeleton--full-width": fullWidth,
          })}
          animate
          data-testid="skeleton"
          {...props}
        />
      ))}
    </>
  );
};

export default LoadingSkeleton;
