import { FC, useMemo } from "react";

import { Shape } from "types/models";
import { ReactComponent as SquareIcon } from "assets/shapes/Square.svg";
import { ReactComponent as CircleIcon } from "assets/shapes/Circle.svg";
import { ReactComponent as TriangleIcon } from "assets/shapes/Triangle.svg";
import { ReactComponent as HexagonIcon } from "assets/shapes/Hexagon.svg";

interface IProps {
  color?: string;
  shape?: Shape;
  className?: string;
}

const tomatoColor = "#c99284";

const ColoredShape: FC<IProps> = ({ color, shape, className }) => {
  const stroke = color || tomatoColor;

  const IconComponent = useMemo(() => {
    switch (shape) {
      case "circle":
        return CircleIcon;
      case "triangle":
        return TriangleIcon;
      case "trapeze":
        return SquareIcon;
      case "hexagon":
        return HexagonIcon;
      default:
        return SquareIcon;
    }
  }, [shape]);

  return (
    <IconComponent
      className={`${className} ${shape === "trapeze" && "trapeze"}`}
      stroke={stroke}
      data-testid={`${shape || "square"}-icon`}
    />
  );
};

export default ColoredShape;
