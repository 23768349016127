import { Auth0Provider, AppState } from "@auth0/auth0-react";
import { QueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import LoginGateway from "./LoginGateway";
import Cookies from "js-cookie";

import "./styles/application.sass";
import CookiePolicy from "components/CookiePolicy";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: AppState | undefined) => {
    if (appState?.returnTo) {
      navigate(appState.returnTo);
    }
    Cookies.set("hasVisited", "true");
  };

  return (
    <div className="app">
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
        redirectUri={window.location.origin}
        scope="read"
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        useRefreshTokens
        cacheLocation="localstorage"
        onRedirectCallback={onRedirectCallback}
      >
        <LoginGateway />
      </Auth0Provider>
      <CookiePolicy />
    </div>
  );
};

export default App;
