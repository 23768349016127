import axios from "axios";
import { filter, isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import BackToScreen from "components/BackToScreen/BackToScreen";
import Chart from "components/Chart";
import ErrorLoading from "components/ErrorLoading";
import FiltersSets from "components/FiltersSets";
import LoadingData from "components/LoadingData";
import SurveyFinishedWrapper from "components/SurveyFinishedWrapper/SurveyFinishedWrapper";
import Breadcrumbs from "components/Breadcrumbs";

import {
  CategoryResults,
  CompanyResponse,
  DefaultIcon,
  FilterSets,
  Shape,
  SurveyDetails,
  TeamResponse,
} from "types/models";
import { filterSetHasResults } from "utils/utils";

import "./styles.sass";
import TeamMemberFilters from "../TeamMemberFilters";
import CategoryFilters from "../Category/CategoryFilters";

const SurveyResults = () => {
  const { companyId, surveyId, teamId } = useParams();
  const [filterSets, setFilterSets] = useState<FilterSets>({});
  const [categoriesToFilter, setCategoriesToFilter] = useState<string[]>([
    "all",
  ]);

  const {
    isLoading: isLoadingSurveys,
    error: errorLoadingSurvey,
    data: survey,
  } = useQuery<SurveyDetails, Error>("publicSurveysList", async () => {
    const { data } = await axios.get<SurveyDetails>(
      `/surveys/${surveyId}/public`
    );
    return data;
  });

  const { data: company } = useQuery<CompanyResponse>(
    `companies${companyId}`,
    async () => {
      const response = await axios.get<CompanyResponse>(
        `/companies/${companyId}`
      );
      return response.data;
    },
    { enabled: !!companyId }
  );

  const { data: team } = useQuery<TeamResponse>(
    `team${teamId}`,
    async () => {
      const response = await axios.get<TeamResponse>(
        `/companies/${companyId}/teams/${teamId}`
      );
      return response.data;
    },
    { enabled: !!teamId && !!companyId }
  );

  const api = useMemo(() => {
    if (teamId) {
      return {
        header: [`teamSurvey-${surveyId}`, companyId, teamId],
        url: `${surveyId}/companies/${companyId}/teams/${teamId}`,
      };
    }
    if (companyId) {
      return {
        header: [`companySurvey-${surveyId}`, companyId],
        url: `${surveyId}/companies/${companyId}`,
      };
    }
    return {
      header: [`publicSurvey-${surveyId}`],
      url: `${surveyId}/companies/`,
    };
  }, [teamId, companyId, surveyId]);

  const description = useMemo(() => {
    if (teamId) {
      return team?.team.name;
    }
    if (companyId) {
      return company?.company.name;
    }
    return "companies.";
  }, [teamId, companyId, team, company]);

  const {
    isLoading: isLoadingSurvey,
    data: surveyResult,
    isSuccess: isSurveyReady,
  } = useQuery<{ [val: string]: CategoryResults }, Error>(
    api.header,
    async () => {
      const { data } = await axios.get<{ [val: string]: CategoryResults }>(
        `/survey-results/${api.url}`
      );
      return data;
    }
  );

  const availableCategories =
    (surveyResult &&
      isSurveyReady &&
      Object.values(surveyResult).map?.((categoryResult) => ({
        value: categoryResult?.categoryId,
        label: categoryResult?.categoryTitle,
      }))) ||
    [];

  const isSurveyAnswered = useMemo<boolean>(() => {
    if (isSurveyReady && surveyResult) {
      return Object.values(surveyResult).every((categoryResults) =>
        categoryResults.avgTrends.every(
          (trend) => trend.avgTrendAnswer !== null
        )
      );
    } else return true;
  }, [isSurveyReady, surveyResult]);

  const defaultIconConfig = useMemo<DefaultIcon>(() => {
    if (team?.team) {
      return {
        pointShape: (team.team.pointShape as Shape) || "circle",
        pointColor: team.team.pointColor,
      };
    }
    if (company?.company) {
      return {
        pointShape: (company.company.pointShape as Shape) || "circle",
        pointColor: company.company.pointColor,
      };
    }
    return { pointShape: "circle", pointColor: "#32A89C" };
  }, [team, company]);

  const backToScreenPath = useMemo(() => {
    if (teamId) {
      return `/companies/${companyId}/team/${teamId}/surveys`;
    }
    if (companyId) {
      return `/companies/${companyId}/surveys`;
    }
    return "/public";
  }, [teamId, companyId]);

  if (!isSurveyAnswered) {
    return <ErrorLoading variant="info" title="No results for this survey" />;
  }

  if (isLoadingSurveys || isLoadingSurvey) {
    return <LoadingData title="Loading survey" />;
  }

  if (errorLoadingSurvey) {
    return <ErrorLoading title="Can't load survey info" />;
  }

  return (
    <div className="survey-response">
      <BackToScreen name="Survey List" path={backToScreenPath} />
      <Breadcrumbs />
      <SurveyFinishedWrapper
        surveyTitle={survey?.title}
        description={`See trends in ${description}`}
      >
        <FiltersSets
          filterSets={filterSets}
          setFilterSets={setFilterSets}
          apiUrl={api.url}
          isPublic={!companyId}
          defaultIconConfig={defaultIconConfig}
          showDefault={!isEmpty(surveyResult)}
        />
        {team?.team?._id && surveyId && companyId && (
          <TeamMemberFilters
            companyId={companyId}
            surveyId={surveyId}
            filterSets={filterSets}
            setFilterSets={setFilterSets}
            teamResponse={team}
          />
        )}
        <CategoryFilters
          availableCategories={availableCategories}
          categoriesToFilter={categoriesToFilter}
          setCategoriesToFilter={setCategoriesToFilter}
        />
        <Chart
          additionalData={filter(
            filter(filterSets, "visible"),
            filterSetHasResults
          )}
          categoriesToFilter={categoriesToFilter}
          showMe
          defaultIconConfig={defaultIconConfig}
          surveyResult={Object.values(surveyResult || {})}
        />
      </SurveyFinishedWrapper>
    </div>
  );
};

export default SurveyResults;
