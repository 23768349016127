import { FC } from "react";
import { Table, Badge } from "@mantine/core";
import { groupBy, keys, map } from "lodash";

import ModalConfirmTrigger from "components/Modals/ModalConfirmTrigger";
import { CompanyRole, Team } from "types/models";

import "./styles.sass";

interface IProps {
  onRemove?: (email: string) => void;
  roles: CompanyRole[];
  teams?: Team[];
}

const EmailWhitelist: FC<IProps> = ({
  onRemove,
  roles: companyRoles,
  teams,
}) => {
  const grouped = groupBy(companyRoles, "email");

  const rows = map(keys(grouped), (email) => {
    const roles = grouped[email];

    return (
      <tr className="company-table" key={email}>
        <td>{email}</td>
        <td className="company-table__content">
          {roles.map((role) => {
            const team = teams?.find(
              (singleTeam) => role.teamId === singleTeam._id
            );
            return (
              <Badge
                size="lg"
                variant="dot"
                className="company-table__badge"
                color={role.userId ? "green" : "yellow"}
                key={role._id}
              >
                <div className="company-table__badge-wrapper">
                  <div>
                    {team && (
                      <span className="company-table__team-name">
                        {team.name + " | "}
                      </span>
                    )}
                    {!role.userId && (
                      <span className="company-table__pending">PENDING </span>
                    )}
                    {role.role}
                  </div>
                  <ModalConfirmTrigger
                    modalMessage="Are you sure you want to delete this role?"
                    onConfirm={() => {
                      onRemove?.(role._id);
                    }}
                    renderTrigger={(setModalVisible) => (
                      <button
                        onClick={() => setModalVisible(true)}
                        className="company-table__x"
                      >
                        X
                      </button>
                    )}
                  />
                </div>
              </Badge>
            );
          })}
        </td>
      </tr>
    );
  });

  return (
    <div className="company-table__wrapper">
      <Table>
        <thead>
          <tr>
            <th>User</th>
            <th>Roles</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );
};

export default EmailWhitelist;
