import { ReactComponent as ChevronDownIcon } from "assets/ChevronDown.svg";
import { Button, Chip, TextInput } from "@mantine/core";
import React, {
  forwardRef,
  PropsWithChildren,
  useCallback,
  useImperativeHandle,
  useState,
  ChangeEvent,
  useMemo,
} from "react";
import "./styles.sass";
import classnames from "classnames";
import { isEmpty, uniq, split, replace } from "lodash";
import { AnimatePresence, motion } from "framer-motion";

const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface IProps {
  onSubmit: (val: string[]) => void;
  denyEmailList: string[];
  companyEmails?: string[];
}

export interface IEmailFormForwardRef {
  anyUnsavedData: () => boolean;
}

const onAnimate = {
  height: "auto",
  opacity: 1,
  transition: {
    height: {
      duration: 0.4,
    },
    opacity: {
      duration: 0.25,
      delay: 0.1,
    },
  },
};

const onExit = {
  height: 0,
  opacity: 0,
  transition: {
    height: {
      duration: 0.4,
      delay: 0.1,
    },
    opacity: {
      duration: 0.25,
    },
  },
};

const EmailForm = forwardRef<IEmailFormForwardRef, PropsWithChildren<IProps>>(
  ({ onSubmit, denyEmailList, companyEmails }, ref) => {
    const [emails, setEmails] = useState<string[]>([]);
    const [value, setValue] = useState<string>("");
    const [emailError, setEmailError] = useState<string | null>(null);
    const [isEmailListCollapsed, setIsEmailListCollapsed] =
      useState<boolean>(true);

    const anyUnsavedData = useCallback(
      () => !!value || emails.length > 0,
      [emails.length, value]
    );

    const filteredEmails = useMemo(() => {
      return uniq(
        companyEmails?.filter((email) => !denyEmailList.includes(email))
      );
    }, [companyEmails, denyEmailList]);

    useImperativeHandle(
      ref,
      () => ({
        anyUnsavedData,
      }),
      [anyUnsavedData]
    );

    const send = useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit(emails);
      },
      [emails, onSubmit]
    );

    const isInList = (email: string) => {
      return emails.includes(email) || denyEmailList?.includes(email);
    };

    const isEmail = (email: string) => {
      return emailRegExp.test(email);
    };

    const isValid = (email: string) => {
      let error = "";

      if (isInList(email)) {
        error = `${email} has already been added.`;
      }

      if (!isEmail(email)) {
        error = `${email} is not a valid email address.`;
      }

      if (error) {
        setEmailError(error);

        return false;
      }

      return true;
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (["Enter", "Tab", ",", " "].includes(event.key)) {
        event.preventDefault();

        const valueTrimmed = value.trim();

        if (valueTrimmed && isValid(valueTrimmed)) {
          setEmails((prev) => [...prev, valueTrimmed]);
          setValue("");
        }
      }
    };

    const handleBlur = (_event: React.FocusEvent<HTMLInputElement>) => {
      const valueTrimmed = value.trim();

      if (valueTrimmed && isValid(valueTrimmed)) {
        setEmails((prev) => [...prev, valueTrimmed]);
        setValue("");
      }
    };

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      setValue(evt.target.value);
      setEmailError(null);
    };

    const handleDelete = (email: string) => {
      setEmails((prev) => [...prev.filter((i) => i !== email)]);
    };

    const handlePaste = (evt: React.ClipboardEvent<HTMLInputElement>) => {
      evt.preventDefault();

      let error = "";

      const paste = evt.clipboardData.getData("text");

      const pastedEmailsList = uniq(
        split(replace(paste, /[[,; ]{1,}|]/g, " "), " ")
      );

      const invalidEmails = pastedEmailsList
        .filter((email) => !isEmail(email))
        .join(", ");

      if (invalidEmails) {
        error = `${invalidEmails} is not a valid email address.`;
      }

      const validEmails = pastedEmailsList.filter((email) => isEmail(email));

      const duplicatedEmails = validEmails
        .filter((email) => isInList(email))
        .join(", ");

      if (duplicatedEmails) {
        // if there is already error set, go to next line and add a new error, if not, just set the error
        if (emailError)
          error += `\n${duplicatedEmails} has already been added.`;

        error = `${duplicatedEmails} has already been added.`;
      }

      setEmailError(error);

      const toBeAdded = validEmails.filter((email: string) => !isInList(email));

      if (toBeAdded) {
        setEmails((prev) => [...prev, ...toBeAdded]);
      }
    };

    const handleCheck = (evt: ChangeEvent<HTMLInputElement>) => {
      const email = evt.target.value;
      if (isInList(email)) {
        handleDelete(email);
        return;
      }
      setEmails((prev) => [...prev, email]);
    };

    const emailList = isEmpty(filteredEmails) ? (
      <p className="email-list-empty">
        Looks like there is no available emails
      </p>
    ) : (
      <div className="email-list__container">
        {filteredEmails?.map((email, index) => (
          <div key={`${email}-${index}`} className="email-list__list-item">
            <label className="email-list__label">
              <input
                className="email-list__checkbox"
                type="checkbox"
                value={email || ""}
                onChange={handleCheck}
                checked={isInList(email)}
              />
              {email}
            </label>
          </div>
        ))}
      </div>
    );

    return (
      <form className="email-form" data-testid="email-form" onSubmit={send}>
        {!!emails.length && (
          <div className="email-items">
            {emails.map((item) => (
              <Chip
                size="sm"
                checked
                variant="filled"
                color="cyan"
                className="email-item"
                key={item}
              >
                {item}
                <button
                  type="button"
                  className="email-item__delete"
                  onClick={() => handleDelete(item)}
                >
                  &times;
                </button>
              </Chip>
            ))}
          </div>
        )}

        <TextInput
          classNames={{
            input: "email-input",
          }}
          value={value}
          error={emailError}
          placeholder="Type or paste email/s and press `Enter`..."
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onPaste={handlePaste}
          onBlur={handleBlur}
          size="lg"
        />

        {companyEmails && (
          <div className="email-list">
            <div
              role="button"
              className="email-list__list-button"
              onClick={() => setIsEmailListCollapsed((prev) => !prev)}
            >
              <span>View emails available in your company...</span>
              <ChevronDownIcon
                className={classnames("chevron-icon", {
                  "chevron-icon-active": !isEmailListCollapsed,
                })}
              />
            </div>
            <AnimatePresence>
              {!isEmailListCollapsed && (
                <motion.div
                  initial={{
                    height: 0,
                    opacity: 0,
                  }}
                  animate={onAnimate}
                  exit={onExit}
                >
                  {emailList}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}

        <Button
          disabled={emails.length < 1}
          className="email-form__submitButton"
          type="submit"
        >
          Send invites
        </Button>
      </form>
    );
  }
);

export default EmailForm;
