import { useNavigate } from "react-router-dom";
import StatusIcon from "./StatusIcon";
import StatusInfo from "./StatusInfo";
import { ResponseItem as ResponseItemType } from "types/models";

import "./styles.sass";

interface Props {
  item: ResponseItemType;
  navigateUrl: string;
}

const ResponseItem = ({ item, navigateUrl }: Props) => {
  const navigate = useNavigate();
  const { _id, title, completionStatus } = item.survey;

  return (
    <li
      key={_id}
      className="response-item"
      onClick={() => navigate(navigateUrl + _id)}
    >
      <StatusIcon status={completionStatus} />
      <span className="response-item__name">
        {title}
        {completionStatus === "new" && (
          <span className="response-item__new-indicator">New</span>
        )}
      </span>
      <StatusInfo status={completionStatus} />
    </li>
  );
};

export default ResponseItem;
