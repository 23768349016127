import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as PersonIcon } from "assets/Person.svg";
import { ReactComponent as CompanyIcon } from "assets/Company.svg";
import { ReactComponent as TeamIcon } from "assets/Team.svg";
import { ComplexRole } from "types/models";

export enum RESULT_TYPE {
  TEAM,
  COMPANY,
  OWN,
}

type IProps = { title: string; type: RESULT_TYPE; role?: ComplexRole };

const ResultItem = ({ title, type, role }: IProps) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    switch (type) {
      case RESULT_TYPE.TEAM:
        return navigate(
          `/companies/${role?.company._id}/team/${role?.team._id}/surveys`
        );
      case RESULT_TYPE.COMPANY:
        return navigate(`/companies/${role?.company._id}/surveys`);
      default:
        return navigate("/take");
    }
  }, [navigate, role?.company?._id, role?.team?._id, type]);

  const icon = useMemo(() => {
    switch (type) {
      case RESULT_TYPE.TEAM:
        return <TeamIcon />;
      case RESULT_TYPE.COMPANY:
        return <CompanyIcon />;
      default:
        return <PersonIcon />;
    }
  }, [type]);

  return (
    <li className="results-list__survey" onClick={handleClick}>
      {icon}
      <span className="results-list__name">{title}</span>
    </li>
  );
};

export default ResultItem;
