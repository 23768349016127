import { useContext, useEffect, useRef } from "react";
import {
  TextInput,
  Button,
  Textarea,
  Select,
  ColorPicker,
  Text,
} from "@mantine/core";
import { useNotifications } from "@mantine/notifications";
import { useMutation } from "react-query";
import axios from "axios";
import { useFormik } from "formik";

import { ProfileContext } from "routes";
import { queryClient } from "App";
import useDefaultErrorHandler from "hooks/useDefaultErrorHandler";
import { Team } from "types/models";
import { stringHasInvisibleCharacters } from "utils/utils";

import "./styles.sass";

const TeamForm = ({
  initialValues,
  onClose,
  companyId,
  teamId,
}: {
  initialValues?: Partial<Team>;
  onClose: () => void;
  companyId?: string;
  teamId?: string;
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isUpdate = !!initialValues;

  const { invalidateProfile } = useContext(ProfileContext);
  const { onErrorWithTitle } = useDefaultErrorHandler();
  const { showNotification } = useNotifications();

  const onSuccess = () => {
    invalidateProfile();
    queryClient.invalidateQueries(`companies${companyId}`);
    queryClient.invalidateQueries(`team${teamId}`);
  };

  const createMutation = useMutation(
    (newTeam: Partial<Team>) => {
      return axios
        .post<Partial<Team>>(`/companies/${companyId}/teams`, newTeam)
        .then(onClose);
    },
    {
      onSuccess,
      onError: onErrorWithTitle("Can not create team"),
    }
  );

  const updateMutation = useMutation(
    (team: Partial<Team>) => {
      return axios
        .patch<Partial<Team>>(`/companies/${companyId}/teams/${teamId}`, team)
        .then(onClose);
    },
    {
      onSuccess,
      onError: onErrorWithTitle("Can not update team"),
    }
  );

  const {
    handleSubmit,
    handleChange,
    values: formValues,
  } = useFormik<Partial<Team>>({
    initialValues: initialValues || {
      name: "",
      description: "",
      pointShape: "trapeze",
      pointColor: "#a1a1a1",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validate: (values) => {
      if (stringHasInvisibleCharacters(values.name || "")) {
        showNotification({
          color: "red",
          title: `Can not ${isUpdate ? "update" : "create"} team`,
          message: "Invalid team name",
        });
        return { name: "Invalid team name!" };
      }
    },
    onSubmit: (val) => {
      const values = { ...val, name: val.name?.trim() };
      return isUpdate
        ? updateMutation.mutate(values)
        : createMutation.mutate(values);
    },
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      return inputRef && inputRef.current!.focus();
    }, 1);

    return () => clearTimeout(timeoutId);
  }, []);

  const classNames = {
    root: "team-form__input",
    input: "team-form__input-area",
    label: "team-form__label",
  };

  return (
    <form className="team-form" data-testid="team-form" onSubmit={handleSubmit}>
      <TextInput
        classNames={classNames}
        ref={inputRef}
        required
        label={formValues.name && "Team name"}
        placeholder="Team name"
        value={formValues.name}
        onChange={handleChange("name")}
      />

      <Textarea
        classNames={classNames}
        label={formValues.description && "Team description"}
        placeholder="Team description"
        value={formValues.description}
        onChange={handleChange("description")}
      />

      <Select
        classNames={{ ...classNames, dropdown: "team-form__dropdown" }}
        label="Shape"
        placeholder="Pick one"
        data={[
          { value: "triangle", label: "triangle" },
          { value: "square", label: "square" },
          { value: "circle", label: "circle" },
          { value: "trapeze", label: "trapeze" },
        ]}
        onChange={(e: string) => handleChange("pointShape")(e)}
        value={formValues.pointShape}
      />
      <Text className="team-form__color-label">Color</Text>
      <ColorPicker
        className="team-form__color"
        format="hex"
        value={formValues.pointColor}
        onChange={handleChange("pointColor")}
        size="md"
      />
      <Button className="team-form__submit" type="submit">
        {createMutation.isLoading || updateMutation.isLoading
          ? "Loading"
          : isUpdate
          ? "Update"
          : "Create"}
      </Button>
    </form>
  );
};

export default TeamForm;
