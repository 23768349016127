import axios from "axios";
import { Modal } from "@mantine/core";
import { filter, groupBy, isEmpty, keys, map } from "lodash";
import { useContext, useMemo, useState, useCallback } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import CompanyForm from "components/Company/CompanyForm";
import useDefaultErrorHandler from "hooks/useDefaultErrorHandler";
import ModalConfirmTrigger from "components/Modals/ModalConfirmTrigger";

import { ProfileContext } from "routes";
import { ComplexRole } from "types/models";

import "./styles.sass";

export const RoleManagement = () => {
  const { profile, invalidateProfile } = useContext(ProfileContext);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [modalCompanyName, setModalCompanyName] = useState("");
  const filteredProfilePrivileges = useMemo(
    () => filter(profile?.privileges, ({ role }) => role !== "SUPER_ADMIN"),
    [profile]
  );
  const emptyRoles = useMemo(
    () => isEmpty(filteredProfilePrivileges),
    [filteredProfilePrivileges]
  );
  const navigate = useNavigate();
  const { onErrorWithTitle } = useDefaultErrorHandler();

  const removeRoleMutation = useMutation(
    async (roleId: string) => {
      return axios.delete(`/role/${roleId}/leave`);
    },
    {
      onSuccess: () => invalidateProfile(),
      onError: onErrorWithTitle("Can not remove role"),
    }
  );

  const leaveRole = useCallback(
    (item: ComplexRole) => {
      removeRoleMutation.mutate(item.roleId);
    },
    [removeRoleMutation]
  );

  const goToManage = useCallback(
    (item: ComplexRole) => {
      switch (item.role) {
        case "SUPER_ADMIN":
          navigate("/admin/surveys");
          break;
        case "COMPANY_ADMIN":
          navigate(`/companies/${item.company?._id}`);
          break;
        case "TEAM_LEADER":
          navigate(`/companies/${item.company?._id}/team/${item.team?._id}`);
          break;
        default:
          break;
      }
    },
    [navigate]
  );

  const goToResults = useCallback(
    (item: ComplexRole) => {
      switch (item.role) {
        case "COMPANY_ADMIN":
          navigate(`/companies/${item.company?._id}/surveys`);
          break;
        case "TEAM_LEADER":
          navigate(
            `/companies/${item.company?._id}/team/${item.team?._id}/surveys`
          );
          break;
        default:
          break;
      }
    },
    [navigate]
  );

  const content = useMemo(() => {
    if (emptyRoles) {
      return (
        <span>
          You don't have any associated companies, you can create your own or
          ask any other user to invite you
        </span>
      );
    }

    const grouped = groupBy(filteredProfilePrivileges, "company._id");
    return map(keys(grouped), (companyId) => {
      const company = grouped[companyId][0]?.company;
      const roles = grouped[companyId];
      const rolesWithoutNormalUsers = roles.filter((currentRole) => {
        if (currentRole.role === "USER") {
          if (currentRole.team) {
            const higherRoleInTeam = roles.find(
              (role) =>
                role.role !== "USER" && role.team?._id === currentRole.team?._id
            );
            return !higherRoleInTeam;
          } else {
            const higherRoleInCompany = roles.find(
              (role) =>
                role.role !== "USER" &&
                role.company?._id === currentRole.company?._id
            );
            return !higherRoleInCompany;
          }
        }
        return true;
      });
      return (
        <div key={company._id} className="management__company">
          <span className="management__name">Company: {company?.name}</span>
          <ul className="management__roles-wrapper">
            {rolesWithoutNormalUsers.map(
              (item) =>
                item && (
                  <li key={item.roleId} className="management__role">
                    <span className="management__icon">
                      {item.team
                        ? `${item.role} OF TEAM ${item.team.name}`
                        : item.role}
                    </span>
                    <div>
                      {item.role !== "USER" && (
                        <>
                          <a
                            onClick={() => goToResults(item)}
                            className="management__results"
                          >
                            Results
                          </a>
                          <a
                            onClick={() => goToManage(item)}
                            className="management__manage"
                          >
                            Manage
                          </a>
                        </>
                      )}

                      <ModalConfirmTrigger
                        modalMessage={`Are you sure you want to leave ${modalCompanyName}?`}
                        onConfirm={() => {
                          leaveRole(item);
                        }}
                        renderTrigger={(setModalVisible) => (
                          <a
                            onClick={() => {
                              setModalVisible(true);
                              setModalCompanyName(item.company.name);
                            }}
                            className="management__leave"
                          >
                            Leave
                          </a>
                        )}
                      />
                    </div>
                  </li>
                )
            )}
          </ul>
        </div>
      );
    });
  }, [
    emptyRoles,
    filteredProfilePrivileges,
    modalCompanyName,
    goToResults,
    goToManage,
    leaveRole,
  ]);

  return (
    <>
      <div className="management">
        <a
          onClick={() => setCreateModalVisible(true)}
          className="management__company-button"
        >
          Create company
        </a>
        <h1 className="management__headline">Your companies and roles</h1>
        {content}
      </div>
      <Modal
        opened={createModalVisible}
        onClose={() => setCreateModalVisible(false)}
        title="Create Company"
      >
        <CompanyForm onClose={() => setCreateModalVisible(false)} />
      </Modal>
    </>
  );
};

export default RoleManagement;
