import axios from "axios";

import { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { filter, isArray, isEmpty, sortBy } from "lodash";

import ErrorLoading from "components/ErrorLoading";
import LoadingSkeleton from "components/LoadingSkeleton";

import { Survey } from "types/models";
import { ReactComponent as BGIcons } from "assets/BGIcons.svg";

import "./styles.sass";
import ResultItem from "./ResultItem";

import { RESULT_TYPE } from "./ResultItem/ResultItem";
import { ProfileContext } from "routes";

const Results = () => {
  const navigation = useNavigate();
  const { profile } = useContext(ProfileContext);

  const rolesWhereCanCheckResultsSorted = useMemo(
    () =>
      sortBy(
        filter(
          profile?.privileges,
          ({ role }) => role !== "SUPER_ADMIN" && role !== "USER"
        ),
        (role) => (role.role === "COMPANY_ADMIN" ? 0 : 1)
      ),
    [profile]
  );

  const { isLoading, error, data } = useQuery<Survey[]>(
    "surveysAll",
    async () => {
      const response = await axios.get<Survey[]>("/surveys");
      return response.data;
    }
  );

  const filteredByPublic = data
    ?.filter((survey) => survey.isPublic)
    .filter((survey) => !survey.archived);

  useEffect(() => {
    if (data?.length === 1) {
      const surveyId = data[0]?._id;
      navigation("/response/" + surveyId);
    }
  }, [data, navigation]);

  const content = useMemo(() => {
    if (isLoading) return <LoadingSkeleton />;

    if (error) return <ErrorLoading title="Can't load results" />;

    const mappedData = filteredByPublic?.map((el) => ({
      survey: el,
    }));

    return (
      isArray(mappedData) &&
      (isEmpty(mappedData) ? (
        <h3 className="results-list__empty">No available surveys yet</h3>
      ) : (
        <ul className="results-list__surveys">
          <ResultItem key="own" title="Own" type={RESULT_TYPE.OWN} />
          {rolesWhereCanCheckResultsSorted.map((role) => (
            <ResultItem
              key={role.roleId}
              role={role}
              title={
                role.role === "COMPANY_ADMIN"
                  ? `${role.company?.name}`
                  : `${role.company?.name}  >  ${role.team.name} `
              }
              type={
                role.role === "COMPANY_ADMIN"
                  ? RESULT_TYPE.COMPANY
                  : RESULT_TYPE.TEAM
              }
            />
          ))}
        </ul>
      ))
    );
  }, [error, filteredByPublic, isLoading, rolesWhereCanCheckResultsSorted]);

  // TODO map results together with surveys

  return (
    <div className="results-list">
      <h1 className="results-list__headline">Check results</h1>
      {content}
      <div className="svg-background">
        <BGIcons />
      </div>
    </div>
  );
};

export default Results;
