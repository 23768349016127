import { get } from "lodash";
import { FiltersSet } from "types/models";
import chroma from "chroma-js";

export const getRandomLightColor = () => {
  let color = chroma.random();
  if (color.luminance() < 0.7) {
    color = color.saturate().brighten();
  }
  return color.hex();
};

export const getColorByEmail = (email: string) => {
  if (email === "") return chroma("white").hex();
  let hash = 0;
  const username = email.split("@")[0];
  username.split("").forEach((letter) => {
    hash = letter.charCodeAt(0) + (hash << 5) - hash;
  });
  let hexColor = Math.abs(hash).toString(16).slice(0, 6);
  while (hexColor.length < 6) {
    hash *= username.length;
    hexColor = Math.abs(hash).toString(16).slice(0, 6);
  }
  let color = chroma(hexColor);
  if (color.luminance() < 0.7) {
    color = color.saturate().brighten();
  }
  return color.hex();
};

export const filterSetHasResults = (fitlerSets: FiltersSet): boolean => {
  const firstResult = get(
    fitlerSets,
    "categories.0.avgTrends.0.avgTrendAnswer"
  );
  return Boolean(firstResult);
};

export const stringHasInvisibleCharacters = (string: string): boolean => {
  const regex = new RegExp(/\p{C}/u);
  return regex.test(string);
};
