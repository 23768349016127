import { useNavigate } from "react-router-dom";
import "./styles.sass";
import classNames from "classnames";

const ErrorLoading = ({
  title = "Can't load this data",
  variant,
}: {
  title?: string;
  variant?: "info" | "danger";
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={classNames("loading-failed", {
        [`-${variant}`]: variant,
      })}
    >
      <h1 className="loading-failed__headline">{title}</h1>
      <span
        role="button"
        className="loading-failed__go-back"
        onClick={() => navigate(-1)}
      >
        Go back
      </span>
    </div>
  );
};

export default ErrorLoading;
