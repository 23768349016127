import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Center, Text, Title } from "@mantine/core";
import Cookies from "js-cookie";

import LoadingData from "components/LoadingData";
import { ReactComponent as Manager } from "assets/Manager-background.svg";
import { ReactComponent as Employer } from "assets/Employer-background.svg";
import { ReactComponent as Thunder } from "assets/Thunder.svg";

import "welcome-screen.sass";

const WelcomeScreen = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      localStorage.setItem("redirectPath", location.pathname);
      const hasVisited = Cookies.get("hasVisited");
      if (hasVisited) {
        navigate("/landing");
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated, isLoading]);

  if (isLoading) {
    return <LoadingData />;
  }

  return (
    <div className="welcome-screen">
      <div className="login-box login-box-manager">
        <Center className="login">
          <Title order={1} className="login__header">
            <Text className="login__header-span">I am a manager!</Text>
          </Title>
          <Text className="login__text">
            A good leader fosters a positive working environment and ensures the
            right level of empowerment.
          </Text>
          <Button
            className="login__button --manager"
            onClick={() => {
              navigate("/intro/leader");
            }}
          >
            {isLoading ? "Loading" : "Get Started"}
          </Button>
        </Center>
        <div className="login-background">
          <Manager />
        </div>
      </div>
      <div className="login-box login-box-employee">
        <Center className="login">
          <Title order={1} className="login__header">
            <Text className="login__header-span">I am an employee!</Text>
          </Title>
          <Text className="login__text">
            A good leader fosters a positive working environment and ensures the
            right level of empowerment.
          </Text>
          <Button
            className="login__button"
            onClick={() => {
              navigate("/intro/member");
            }}
          >
            {isLoading ? "Loading" : "Get Started"}
          </Button>
        </Center>
        <div className="login-background">
          <Employer />
        </div>
      </div>
      <div className="thunder">
        <Thunder />
      </div>
    </div>
  );
};

export default WelcomeScreen;
